import { Modal, Button } from 'react-bootstrap'

type IModalUserExist = {
  show: boolean
  onHide: () => void
}

const ModalUserExist = ({ show, onHide }: IModalUserExist) => {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Body>
        <p className="text-confirm">
          すでに登録済のメールアドレスです。<br />
          ログイン画面に遷移します。<br />
          ログインする　別のメールアドレスで登録する
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} className="cancel">
          中断
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalUserExist
