import { Pdf, data_Pdf, data_Company, Company, LoginUser, DataAnswer, Answer, data_email, IRegister, ICheckAuthCode, IForgotPassword, IResetPassword } from './questionType'
import { post, get, deleteRequest } from '../../services/api/api'
import { AxiosRequestConfig } from 'axios'
import utlis from '../../utils'

// tslint:disable-next-line:variable-name
export function previewPdf(answer_pdf: Pdf, config?: AxiosRequestConfig): Promise<data_Pdf> {
  return post('/answer/v1/answer-pdf/', answer_pdf) as Promise<data_Pdf>
}
export function postAnswer(answer_web: Answer, config?: AxiosRequestConfig): Promise<DataAnswer> {
  return post('/answer/v1/answer-web/', answer_web) as Promise<DataAnswer>
}
export function postAnswerUser(answer_web: Answer, config?: AxiosRequestConfig): Promise<DataAnswer> {
  return post('/answer/v1/', answer_web, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<DataAnswer>
}
export function checkCompany(
  // tslint:disable-next-line:variable-name
  company_code: Company,
  config?: AxiosRequestConfig
): Promise<data_Company> {
  return post('/account/v1/check-exist-company/', company_code) as Promise<data_Company>
}

export function checkEmail(
  // tslint:disable-next-line:variable-name
  data: any,
  config?: AxiosRequestConfig
): Promise<data_email> {
  return post('/account/v1/check-exist-email/', data) as Promise<data_email>
}

export function updateProfile(data: any, config?: AxiosRequestConfig): Promise<DataAnswer> {
  return post('/account/v1/update-profile/', data, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<DataAnswer>
}

export function Login(profile: LoginUser, config?: AxiosRequestConfig): Promise<any> {
  return post('/auth/v1/login/', profile) as Promise<any>
}

export function Register(data: IRegister, config?: AxiosRequestConfig): Promise<any> {
  return post('/auth/v1/register/', data) as Promise<any>
}

export function checkAuthCode(data: ICheckAuthCode, config?: AxiosRequestConfig): Promise<any> {
  return post('/account/v1/check-auth-code/', data) as Promise<any>
}

export function requestForgotPassword(data: IForgotPassword, config?: AxiosRequestConfig): Promise<any> {
  return post('/account/v1/send-link-forgot-password/', data) as Promise<any>
}


export function requestPassword(data: IResetPassword, config?: AxiosRequestConfig): Promise<any> {
  return post('/auth/v1/reset-password/', data) as Promise<any>
}

export async function getListQuestion(id: { id: any }, config?: AxiosRequestConfig): Promise<any> {
  return get(`/question/v1/${id}/`, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function getListSurvey(): Promise<any> {
  return get(`/question/v1/all/`, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function getProfile(): Promise<any> {
  return get(`/account/v1/profile/`, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function sendEmailToUserApi(data: any): Promise<any> {
  return post(`/export/v1/send-email/send-mail-user/`, data, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function changePassword(data: any): Promise<any> {
  return post(`/auth/v1/change-password/`, data, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function deleteUser(): Promise<any> {
  return deleteRequest(`/account/v1/delete-account/`, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function questionSearch(dataSearch: any): Promise<any> {
  return get(`/question/v1/question-search/?${dataSearch}`, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

export async function getAnswerQuestion(dataSearch: any): Promise<any> {
  return get(`/answer/v1/question/?${dataSearch}`, {
    headers: {
      Authorization: `JWT ${utlis.checkAuth()} `,
    },
  }) as Promise<any>
}

