import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ToastProvider } from 'react-toast-notifications'
import { Routes } from './router/Routes'
import { LayoutSplashScreen } from './layouts/MetronicSplashScreen'

function App({ store, basename }: any) {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={3000}>
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/* Render routes with provided `Layout`. */}
            <Routes />
          </BrowserRouter>
        </React.Suspense>
      </Provider>
    </ToastProvider>
  )
}

export default App
