import { useState } from 'react'
import { pdfjs } from 'react-pdf'
import HashLoader from 'react-spinners/HashLoader'
import './term.scss'
import { SURVEY } from '../../lang/Survey.lang'
import { css } from '@emotion/core'
interface Props {
  onNavigate: () => void
}
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #56ac8a;
`

export const htmlTermAndService = () => {
  return (
    <div style={{ height: '100%' }} className="term-pdf" id="pdf-wrapper">
        {/* <Document file={policyPdf} onLoadSuccess={onDocumentLoadSuccess} loading={loadingPdf()}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={800}
              onLoadSuccess={() => setLoading(false)}
            />
          ))}
        </Document> */}
        <p className="s5">Happiness Book 
          <span className="s6">利用規約</span>
        </p>
        <p className="s7">Social Healthcare Design 
          <span className="p">株式会社（以下「当社」といいます）が提供する </span>Happiness Book
          <span className="p">を利用するもの（以下「本ユーザー」といいます）は、以下の利用規約（以下「本規約」といいます）に従うものとします。</span>
        </p>
        <p className="space">
          <br/>
        </p>
        <p className="s6">第１章 用語</p>
        <p className="space">
          <br/>
        </p>
        <p className="title-1">第1条 （用語の定義）</p>
        <p className="des-1">本規約に用いる語句の定義は以下のとおりとします。</p>
        <p className="content-1-1">(1) 「オプションサービス」とは、本サービスの提供において、本ユーザーの希望に応じて、本サービスに標準的に定められた実施範囲を超えて提供されるサービスをいいます。</p>
        <p className="content-1-text">(2) 「カウンセリングサービス」とは、対価の有無にかかわらず、個人の抱える問題・悩みなどに対し、専門的な知識や技術を用いて行われる相談援助を行うサービスをいい、臨床心理士、公認心理師など特定の公的資格を保有するものによって行われるものを含みますがこれらに限られません。</p>
        <p className="content-1-text">(3) 「各種申請フォーム」とは、本サービスのウェブサイト内にある各種申請をするための入力を行う操作画面をいいます。</p>
        <p className="content-1-text">(4) 「関連サービス」とは、当社のソリューションパートナーまたはその他の業務提携先が提供するサービスであって、その一部に本サービスを含み、または本サービスと関連づけられたサービスをいいます。</p>
        <p className="content-1-text">(5) 「企業等」とは、企業、団体、政府機関、自治体、組合、学術機関または教育機関の総称もしくはその一部をいいます。</p>
        <p className="content-1-text">(6) 「基本利用期間」とは、本ユーザーが本サービスを利用する、利用開始月から利用終了月までの期間をいい、詳細はサービス一覧表に定めます。</p>
        <p className="content-1-text">(7) 「更新期間」とは、基本利用期間満了後、本ユーザーから、当社所定の方法による手続きを行うことにより本契約を更新しない旨の通知が当社に出され、当社が解約手続の完了を本ユーザーに通知した利用終了月までの期間をいいます。</p>
        <p className="content-1-text">(8) 「個人情報」とは、本サービスを通じて当社が取得したユーザー関連情報のうち、個人情報の保護に関する法律（以下「個人情報保護法」といいます）に規定される個人情報をいいます。</p>
        <p className="content-1-text">(9) 「個人用評価書」とは、本ユーザーの評価結果及び 
          <span className="s7">Well-Being </span>度を表示する文章（電磁的媒体であるかを問いません）またはウェブサイト上のページをいいます。なお、個人用評価書はオプションサービスのため本サービスとは別途の有償とします。また個人用評価書の名称や記載内容は、後日本ユーザーに対する予告なく変更されることがあるものとします。
        </p>
        <p className="content-1-10">(10) 「所属グループ」とは、本ユーザーが２人以上集まったグループのことをいいます。</p>
        <p className="content-1-11">(11) 「ソリューションパートナー」とは、本サービス及びその評価結果を利用して本ユーザーに対しカウンセリングサービスまたは他の健幸サービスを提供する事業者をいいます。</p>
        <p className="content-1-12">(12) 「登録情報」とは、本サービスの申込み時に申込フォームに記載する方法により当社に提供したユーザー関連情報をいいます。</p>
        <p className="content-1-13">(13) 「登録情報変更フォーム」とは、本サービスのウェブサイト内にある登録情報を変更するための入力を行う操作画面をいいます。</p>
        <p className="content-1-14">
        (14) 「パスワード」とは、ログインID と組み合わせて、本サービスへのアクセス認証のために使用される文字列をいいます。</p>
        <p className="content-1-15">
        (15) 「評価結果」とは、本ユーザーを本サービスにより分析したことで算出される本サービス上の評価結果をいいます。</p>
        <p className="content-1-text">
        (16) 「不正使用」とは、本ユーザーが自らの過失の有無にかかわらず、第三者によってユーザーアカウントを使用されることをいいます。</p>
        <p className="content-1-17">
        (17) 「本契約」とは、第 2 条に定める本ユーザー登録手続きの完了をもって、本ユーザーと当社との間で成立する契約であって、本規約にしたがって当社が本サービスを提供し、本ユーザーが本サービスを利用することを内容とする契約をいいます。また、本規約の内容は、すべて本契約の条件となるものとします。</p>
        <p className="content-1-text">
        (18) 「本コンサルティングサービス」とは、当社が所属企業等の依頼に応じて、本ユーザーの同意に基づきユーザー関連情報を所属企業等に対して提供し、本ユーザー及び所属グループの Well-Being度 の向上を支援する事を目的としたサービスをいいます。本ユーザーの本サービスコンテンツを閲覧、分析することで、所属企業等が本ユーザー及び所属グループの Well-Being度 の向上を支援するために助言・サポート等を行います。</p>
        <p className="content-1-19">
        (19) 「本サービス」とは、個人向けに Well-Being度 評価のためのアンケート収集、データ分析、分析結果表示及び過去データ蓄積機能を提供し、並びに Well-Being度 に関する助言及びサポートを行うサービスをいいます。</p>
        <p className="content-1-20">
        (20) 「本サービスコンテンツ」とは、本サービスにおいて本ユーザーが利用する一切のサービス並びに本ユーザーに関する個別の評価結果及びその総称をいいます。</p>
        <p className="content-1-21">
        (21) 「オプションサービス利用料金」とは、オプションサービスに対する料金をいいます。</p>
        <p className="content-1-1">
        (22) 「本システム」とは、本サービスを構成するソフトウェアプログラム、コード、ユーザーインターフェースのデザイン等一切の有形・無形の財産及び本サービスの提供に伴い利用されるサーバー機器や各種ネットワーク設備等（クラウドシステムにて提供される運用環境を含みます）をいいます。</p>
        <p className="content-1-text">
        (23) 「メール等」とは、電子メールまたは他のメッセージングサービスもしくはアプリによるメッセージの送受信の手段または送受信の対象をいいます。</p>
        <p className="content-1-24">
        (24) 「ユーザーアカウント」とは、ログインID と パスワード により識別される本ユーザーが本サービスを利用するために、当社によって付与されるアカウントをいいます。</p>
        <p className="content-1-25">
        (25) 「ユーザー関連情報」とは、以下の情報の総称またはその一部をいいます。</p>
        <p className="content-1-25-1">
        ①本ユーザーが本サービスの利用に際して入力し、または本サービスの利用の過程で当社が知得した、本ユーザーの個人情報</p>
        <p className="content-1-25-2">
        ②本ユーザーの本サービス上でのアンケートの回答内容やその評価結果等のプロフィール情報</p>
        <p className="content-1-25-3">
        ③本ユーザーが提供する本ユーザーの属性情報及びその他の情報、並びに本サービスによりこれを分析または解析した情報</p>
        <p className="content-1-text">
        (26) 「利用開始月」とは、本ユーザーが指定した本サービスの利用を開始する月をいいます。本ユーザーが、本サービスの利用を実際に開始する日にかかわらず、利用開始日は利用開始月の１日とします。</p>
        <p className="content-1-text">
        (27) 「利用終了月」とは、本ユーザーが指定した本サービスの利用を終了する月をいいます。本ユーザーが、本サービスの利用を実際に終了する日にかかわらず、利用終了日は利用終了月の末日とします。</p>
        <p className="content-1-28">
        (28) 「ログインID」とは、本サービスへのアクセス認証のために使用される、本ユーザーが独自に持つ識別子をいい、利用企業等または本ユーザーが指定するメールアドレスを利用します。ログインID は本ユーザー１人につき、１つ付与されます。</p>
        <p className="content-1-24">
        (29) 「所属企業等」とは、当社が Happiness Book Premium サービスを提供している企業等であって、本ユーザーが所属する企業等をいいます。</p>
        <p className="content-1-30">
        (30) 「OS」とは、スマートフォンなどに搭載されたオペレーティングシステムをいいます。</p>
        <p className="content-1-31">
        (31) 「Well-Being度」とは、本ユーザーからアンケートによって本ユーザー自身の心身・健康状態、行動分析の認識に関するデータを取得し、当社独自の特許取得アルゴリズムに基づいて算出される数値をいいます。</p>
        <p className="content-1-32">
        (32) 「組織番号」とは、本サービスを利用するにあたり、当社から所属企業等へ割り当てられる本サービスへのアクセス認証のために使用される、利用企業等が独自に持つ識別子をいいます。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="s6">
        第２章 総則</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">
          <a>第2条 （契約の成立）</a>
        </p>
        <p className="content-2-1">
        1 本ユーザーは、本規約の全ての内容に同意した上で、所定の登録フォームより、登録の手続きを行うものとします。</p>
        <p className="content-2-2">
        2 当社は、本ユーザーによる登録手続きの完了をもって、申込を承諾し、本ユーザーと当社との間で本契約が成立するものとします。</p>
        <p className="content-2-3">
        3 本ユーザーは、１つのユーザーアカウントのみを登録できます。</p>
        <p className="content-2-4">
        4 当社は、本ユーザーとして、登録を希望する者を本ユーザーとして承認することが不適切と判断した場合、当該ユーザー登録希望者による本ユーザーへの登録を認めないことができます。また、当社は、ユーザーアカウント開設を承認した後であっても、不適切と判断した場合、ユーザーアカウントの取消しまたは停止を行うことができます。これらの場合、当社はその理由を開示せず、その理由を説明する義務を負いません。</p>
        <p className="content-2-5">
        5 本ユーザーが未成年者である場合には、親権者その他の法定代理人の同意を得たうえで、本サービスを利用するものとします。</p>
        <p className="content-2-4">
        6 本サービスの利用地域は日本国内とし、特に、EU加盟国またはアイスランド、ノルウェーもしくはリヒテンシュタイン（また、GDPR（General Data Protection Regulation：一般データ保護規則）に将来加盟する他の国）に在住している個人に対する本サービスの提供はしないものとします。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="s9">
        第 3 条 （評価結果の利用）</p>
        <p className="content-2-4">
          1 本ユーザーは、本サービスの利用の結果として Well-Being度の評価結果が出力された後、本サービスのウェブサイト、個人用評価書またはその他のドキュメントとして閲覧することができます。</p>
        <p className="content-2-4">
          2 本ユーザーは、Well-Being度の評価結果を、自己の責任で、かつもっぱら自己のために利用するものとします。</p>
        <p className="content-2-4">
          3 当社は、本ユーザーの評価結果、ユーザー関連情報その他の本ユーザーから取得した情報を利用します。但し、個人情報に該当する情報については、当社が別途定める「個人情報の取り扱い」に従い、取り扱うものとします。</p>
        <p className="content-2-4">
          4 当社は、本ユーザーから提供されたデータを、個人が特定できない形に統計化した上で、利用することができるものとし、統計化したデータを利用企業等に提供できるものとします。</p>
        <p className="content-2-4">
          5 当社は、所属企業等に対して、本ユーザーの Well-Being度を向上させるため、当該本ユーザーの同意に基づき、当該本ユーザーの評価結果、ユーザー関連情報その他の本ユーザーから取得した全ての情報を提供します。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="s9">
          第 4 条 （ソリューションパートナー）</p>
        <p className="content-2-4">
          1 当社は、当社が推奨した特定のソリューションパートナーに対して、当該本ユーザーの同意に基づき、当社が本ユーザーのユーザー関連情報を当該ソリューションパートナーに対して提供することができます。</p>
        <p className="content-2-4">
          2 前項の場合、当社は、本ユーザーに対してソリューションパートナーの名称及び本ユーザーのユーザー関連情報を提供する目的を明示したうえで、当該ソリューションパートナーの推奨を行うものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 5 条 （登録情報変更の届出義務）</p>
        <p className="content-2-4">
          1 本ユーザーは、登録情報に変更が生じた場合、当社に対し、登録情報変更フォームから速やかに届け出るものとします。当社は、本条に基づく登録情報の変更の届出を本ユーザーが行わなかったことによる不利益について、一切責任を負わないものとします。</p>
        <p className="content-5-2">
          2 本ユーザーは、登録情報が全て正確であることを保証します。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 6 条 （ログインID・パスワードの管理）</p>

        <p className="content-2-4">
          1 本ユーザーは、自らの責任に基づき ログインID 及び パスワード を適切に管理、保管するものとし、これらを第三者に利用、貸与、譲渡等してはなりません。</p>
        <p className="content-2-4">
          2 本ユーザーは、不正使用、その他第三者による ログインID や パスワードの盗難 が判明した場合、直ちにその旨を当社に通知します。万一、不正使用により当社に損害が生じた場合、本ユーザーは、当該損害を賠償する責を負うものとします。</p>
        <p className="content-2-4">
          3 当社は、登録情報またはユーザーアカウントの不正使用によって生じる一切の事象について、本ユーザーによる利用とみなし一切の責任を負わないものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 7 条（再委託）</p>

        <p className="content-2-4">
          当社は、本サービス提供に関して、その一部を、自己の責任で第三者に委託することができます。この場合、当社は当該委託先に対し、本契約において本ユーザーに対して負う義務と同等の義務を遵守させるものとし、当該委託先の義務違反について、ユーザーに対し直接責任を負うものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 8 条 （本システム等の権利の帰属）</p>

        <p className="content-2-4">
          1 本システム、本サービス及び本サービスコンテンツ並びにこれらを分析した結果得られた知見及びノウハウ等に係る権利及び著作権（著作権法第 27 条及び第 28 条に定める権利を含みます。以下同じ）その他の一切の知的財産権（以下「知的財産権」といいます）は、全て当社に帰属するものとし、本サービスの利用により本ユーザーへのいかなる知的財産権の移転、譲渡も生じないものとします。</p>
        <p className="content-2-4">
          2 当社は、本ユーザーが本システム、本サービスまたは本サービスコンテンツの知的財産権を実質的に侵害したと認められる場合、本サービスの提供を直ちに停止した上で、本ユーザーによる侵害行為を差し止めるためのあらゆる措置をとることができるものとし、本ユーザーは当社によるかかる措置を受け入れるものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 9 条 （コンテンツの取扱い）</p>

        <p className="content-2-4">
          1 当社は、本コンサルティングサービスを提供する目的の範囲内に限り、本ユーザーによる本サービスの利用状況を閲覧、分析することができます（本ユーザーによる本サービスコンテンツの利用状況の閲覧、分析、本ユーザーによる本サービス利用状況の確認を含みますが、これらに限られません）。ただし、本ユーザーが本コンサルティングサービスを希望しない旨を「各種申請フォーム」から要請した場合は、この限りでありません。</p>
        <p className="content-2-4">
          2 当社は、本サービスの運営上必要または適当と判断したときは本サービスコンテンツを改変、削除等することができ、ユーザーはこれに同意します。</p>
        <p className="content-2-4">
          3 当社は、本ユーザーとの間の本契約が終了した場合、または第 14 条に基づき本サービスの提供を終了した場合、当社は適切と考える時期に本サービスコンテンツの一切を削除することができます（ただし当社が適当と考える場合は、保存を継続することができます）。このとき、当社は当該コンテンツのバックアップ等を、本ユーザーに引き渡す義務を負いません。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 10 条（本サービスを通じて取得した情報の取扱い）</p>

          <p className="content-2-4">
            1 本ユーザーは、本サービスの提供に関連して提供されたWell-being度の評価結果、カウンセリングサービスにかかるカウセリング内容、本サービスに関する当社からの回答内容、当社との通信・やり取りの内容、またはその他、適切な表示（「CONFIDENTIAL」「秘」など）により機密である旨を当社が明示した情報について、本規約に示された目的以外では使用せず、当社の事前の同意なくして公開せず、第三者に対して開示しないものとします。本ユーザーのこの義務は、本契約が終了した後も存続します。</p>
          <p className="content-2-4">
            2 Google APIから受け取った歩数情報の使用は、使用制限の要件を含め、<a className="text-link" href="https://developers.google.com/terms/api-services-user-data-policy?hl=ja" target="_blank">Google API サービスのユーザーデータに関するポリシー</a>に準拠します。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 11 条（損害賠償）</p>

        <p className="content-2-4">
          本ユーザーが本契約に違反したことにより、当社が損害を被った場合、本ユーザーは当該損害を賠償する責任を負うものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 12 条（解約）</p>

        <p className="content-2-4">
          1 本ユーザーは、基本利用期間中、本契約を解約することはできません。ただし、基本利用期間中であっても、本ユーザーは、利用終了月までの利用料金及び基本利用期間の満了月までの利用料金相当額を違約金として、一括して当社に支払った場合に限り、本契約を直ちに解約できるものとします。また、本ユーザーが基本利用期間分の利用料金を一括前払した場合、当社は残期間分の利用料金を違約金として充当することができます。</p>

        <p className="content-2-4">
          2 本ユーザーは、更新期間中においても更新期間満了までは、本契約を解約することはできません。ただし、更新期間中であっても、本ユーザーは、利用終了月の前月末日までに、当社に対して当社所定のフォームから通知した上、利用終了月までの利用料金を一括して当社に支払った場合に限り、本契約を解約できます。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 13 条（更新）</p>

        <p className="content-2-4">
          基本利用期間満了月の前月末日までに、本ユーザーから、本契約を更新しない旨の書面による通知がなされない限り、本契約はさらに１ヶ月間延長されるものとし、以後も同様とします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 14 条（本契約の解除・本サービスの提供の終了）</p>

        <p className="content-2-4">
          1 当社は、本ユーザーに次の事由が発生した場合は、何らの通知催告なしに本ユーザーに対する本サービスの提供を終了し、または本契約を解除することができるほか、違反の疑いが生じたときは、本サービスへの当該ユーザーからの利用を停止し、またはアクセスを遮断することができるものとします。</p>

        <p className="content-14-1">
          (1) 利用規約等に違反したとき</p>

        <p className="content-14-2">
          (2) 当社が催告するも当社に対して支払うべき金銭を支払わず、または支払義務を争ったとき</p>

        <p className="content-14-2">
          (3) 当社の信用を著しく毀損したとみなされるとき</p>

        <p className="content-14-2">
          (4) 支払停止状態に陥った場合、その他財産状態が悪化し、またはそのおそれがあると認められる相当の理由があるとき</p>

        <p className="content-14-2">
          (5) 本規約の効力もしくは有効性、または本規約上の義務もしくは責任の存在を争ったとき</p>

        <p className="content-14-2">
          (6) 本サービスまたは本サービスコンテンツにかかる当社の知的財産権の存在または有効性を争ったとき</p>

        <p className="content-2-4">
          2 本ユーザーが死亡したとき、または、成年後見、保佐、もしくは補助開始の申立てをなし、または他からその申立をなされたときは、本契約は当然に終了します。</p>

        <p className="content-2-4">
          3 本ユーザーが第 1 項各号に該当したときは、本ユーザーの当社に対する一切の債務について、期限の利益を失うものとします。</p>

        <p className="content-2-4">
          4 前各項のほか、当社が、ソリューションパートナーとの契約に基づき、ソリューションパートナーが提供する関連サービスの一部、前提または手段として、本ユーザーに本サービスを提供する場合においては、当社とソリューションパートナーの間の契約が終了したときは、何らの通知催告なしに本ユーザーに対する本サービスの提供を終了することができるものとします。</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 15 条（データの保存・削除等）</p>

        <p className="content-2-4">
          1 当社は、本ユーザーの承諾を得ることなく、当社システム等の故障・停止等の復旧の便宜に備えて、別紙「個人情報の取り扱い」に記載の目的のために、またはその他管理上の理由から、ユーザー関連情報を、本契約終了後も含め、任意の期間保存することができるものとします。</p>

        <p className="content-2-4">
          2 当社は、本ユーザーへの本サービス提供が終了した場合、ユーザー関連情報の全部または任意の一部を、当社が適切と考える時期に削除することができるものとします。</p>

        <p className="content-2-4">
          3 本条の規定は、本ユーザーによる本サービスの利用が終了した後も有効に存続するものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
          第 16 条（本サービスの廃止）</p>

        <p className="content-2-4">
          当社は、当社の都合により本サービスの全部、または一部を廃止することができます。この場合には、可能な限り 3 ヶ月前までに当社ウェブサイトで公表するものとします。また当該廃止によって、当社は本ユーザーに何ら補償をする責任を負いません。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第 17 条（本規約の変更）</p>

        <p className="content-2-4">
        1 当社は、以下のいずれかの場合に、必要に応じて、本規約を変更することができます。</p>

        <p className="content-14-2">
        (1) 本規約の変更が、本ユーザーの一般の利益に適合するとき。</p>

        <p className="content-1-32">
        (2) 本規約の変更が、本契約の目的に反せず、かつ、変更の必要性、変更後の本規約の内容の相当性、その他の変更にかかる事情に照らして合理的なものであるとき。</p>

        <p className="content-2-4">
        2 前項において、当社が本規約の変更を行う場合、当社は本ユーザーに対して本システム上または本サービスサイト上等で、速やかに変更内容、条件に関する通知を行うものとし、通知後１か月が経過した時点で変更の効力が生じます。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第 18 条（契約終了後の存続規定）</p>

        <p className="content-2-4">
        本契約終了後においても、第 3 条 2 項から 4 項、第 8 条から第 10 条まで、第 14 条 3 項及び 4 項、第 15 条、第 18 条から第 21 条、第 24 条、第 26 条から第 28 条、第 30 条から第 32 条その他当社の免責を定める規定は、なお有効なものとして存続するものとします。</p>

        <p className="s9">
        第19条 （権利義務譲渡禁止）</p>

        <p className="content-2-4">
        本ユーザーは、本契約上の地位並びに本契約から生じた権利及び義務を、相手方の事前の書面による承諾なく第三者に譲渡し、あるいは担保に供しないものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第20条 （準拠法）</p>

        <p className="content-2-4">
        本契約の準拠法は日本法とします。また、本契約、本規約、または本サービスに関連して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第21条 （通知方法）</p>

        <p className="content-2-4">
        当社から本ユーザーへの通知は、郵送、本ユーザーが登録した電子メールアドレス、その他の通信手段アドレス（チャット等）への送信または当社のウェブサイト上の表示のうち、当社が適切と考える方法をもって行うことができ、本ユーザーの連絡先が変更または誤記のために当該通知が不着だったとしても、当該通知は、電子メールや他の通信手段については発信の翌日に、ウェブサイトにおける表示については表示の日に到達したものとみなします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s6">
        第３章 利用料金</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="content-2-4"></p>

        <p className="s9">
        第22条 （オプションサービスの利用料金）</p>

        <p className="content-2-4">
        1 本ユーザーは、本ユーザーの希望に応じて、当社から提供されるオプションサービスの対価として、当社が別途決済規約で定める方法で、本サービス利用料金を支払うものとします。</p>

        <p className="content-2-4">
        2 当社は、本ユーザーから既に支払われたオプションサービス利用料金については、本契約が中途で解除された場合を含め、いかなる理由があっても返金はしないものとします。</p>

        <p className="content-2-4">
        3 当社は、本ユーザーの承諾無くオプションサービス利用料金額を改定または部分的変更を行うことができるものとし、本ユーザーは、改定日の次に到来する利用期間から、改定後の料金を当社指定の方法で支払うものとします。</p>

        <p className="content-2-4">
        4 本ユーザーがオプションサービス利用料金の全てまたは一部を支払わない場合、未払利用料金に対し年 14．6 ％の割合による遅延損害金を支払います。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s6">
        第４章 免責事項</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第23条 （本サービス提供及び関連サービスによる損害）</p>

        <p className="content-2-4">
        本サービスが、関連サービスの一部として提供される場合であっても、当社は、関連サービスの提供の有無、提供の不備や不具合、関連サービスの適法性、妥当性、正確性、そして関連サービスの品質及び関連サービスによる結果、関連サービスによってユーザーが受ける可能性のある損害、その他関連サービスに関する一切の事項について責任を負わないものとします。</p>

        <p className="s9">
        第24条（本サービスの利用と評価結果に対する責任）</p>

        <p className="content-2-4">
        1 評価結果に含まれる見解または意見（システムや AI 等による機械的分析を含みます）については、本サービス独自の見解または意見であり、その内容に誤りや欠陥があっても如何なる保証をするものでなく、本ユーザーは、自らの特定の判断または行動において、評価結果に依拠することはしないものとします。</p>

        <p className="content-2-4">
        2 本ユーザーは評価結果が特定の医療行為の受診を勧奨するものではなく、また本ユーザーが医療機関への受診または診療を受けるか否か、及びその時期については、常に自己の責任により判断するものとします。また、評価結果がどんな内容であろうと、本ユーザーが心身において異常を感じたり、または何らかの理由で医療機関の診療を受ける必要を感じた場合、本ユーザーは速やかに医療機関を受診します。</p>

        <p className="content-2-4">
        3 前各項のほか、本サービスもしくは評価結果等の使用、不使用またはこれらに基づく本ユーザーの判断もしくは行為の結果については、当社は、理由の如何を問わず一切の責任を負わないものとします。</p>

        <p className="content-2-4">
        4 本サービスの利用に関し本ユーザーが第三者に対し損害を与えた場合、または本ユーザーと第三者との間にトラブルや係争が生じた場合、本ユーザーは自己の責任によりこれを解決するものとし、当社は、理由の如何を問わず一切の責任を負わないものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第25条 （本システムの機能追加、変更）</p>

        <p className="content-2-4">
        本ユーザーは、当社が本システムの機能向上を目的として、または他の目的で、当社の裁量により本システムの機能の追加、変更を行う場合があること、及び、当該追加、変更により、変更前の本サービスの全ての機能、性能が維持されることが保証されるものではないことを理解し、了承するものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s9">
        第26条 （非保証）</p>

        <p className="content-2-4">
        1 当社は、本サービスについて、以下の事項を保証せず、これらにより本ユーザーに生じたいかなる損害についても、免責されます。</p>

        <p className="content-14-2">
        (1) 本サービスへのアクセス可能性、アクセスに中断（中断時間の長短を問いません）、表示または反応速度の低下が生じないこと</p>

        <p className="content-14-2">
        (2) 本サービスが完全性を有するものであり、その提供に障害、不備、または中断がないこと</p>

        <p className="content-14-2">
        (3) 当社システム等においてデータもしくは情報が保存される場合、これが消失せず、または、改ざんもしくは損傷が生じないこと</p>

        <p className="content-14-2">
        (4) 本サービスの利用によって、本ユーザーが期待する健康上の効果、動機づけの効果、または他の効果が生じること</p>

        <p className="content-14-2">
        (5) 本サービスの提供もしくは運用、当社システム等または関連コンテンツが、第三者の知的財産権もしくは他の権利を侵害しないこと、または侵害の主張を受けないこと</p>
        <p className="content-14-2">
        (6) 本サービスコンテンツ、または、本サービスのウェブサイト、サーバー、ドメイン等から送られるメールもしくはメールマガジンなどに各種ウィルスなどの有害な情報が含まれていないこと</p>

        <p className="content-14-2">
        (7) 本サービスに何らかのセキュリティ上の障害が生じないこと</p>

        <p className="content-14-2">
        (8) 本サービスコンテンツの毀損、改ざん等が発生しないこと</p>

        <p className="content-2-4">
        2 前項のほか、本ユーザーは、本サービスを運用するための基礎となる学術的もしくは臨床的所見もしくは理論が、一つの学術的立場に基づくものであって、誤謬または修正もしくは訂正されるべきものが含まれ得ること、真実性、正確性および正当性についての保証がないことを認めます。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="content-27">
        第27条 （免責）</p>

        <p className="content-2-4">
        1 本サービスの提供において、当社の過失により、本ユーザーに損害が生じた場合、当社は本ユーザーに対し、本サービスの利用料金（損害発生時までに本ユーザーが当社に支払った利用料金の総額とします）を上限として、当該損害を賠償する責を負うものとします（この上限金額には、不当利得返還請求権によるものを含みます）。ただし、当社に故意または重過失がある場合は本項の損害賠償の上限に関する規定は適用されないものとします。</p>

        <p className="content-2-4">
        2 前項の場合、賠償の範囲は、直接かつ現実の損害に限られ、逸失利益もしくは他の利益喪失、事業、就業もしくは診療・治療機会の損失、または、間接的損害、特別損害（予見可能性を問いません）はいかなる場合も賠償の対象としないものとします。</p>

        <p className="content-2-4">
        3 当社が本ユーザーに対しアドバイスを含む情報提供を行う場合であっても、それらについては第 24 条を適用するものとし、当社は何ら責任を負いません。</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="s6">
        第５章 確認事項</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="title-1">
        第28条 （本サービスの利用における確認事項）</p>

        <p className="content-28-1">
        本ユーザーは、本サービスの利用にあたり、以下の事項を確認、承諾するものとします。</p>

        <p className="content-1-1">
        (1) 本サービスが、当社によって提供されるものであることを了承し、本サービスにおいて本ユーザーが提供する秘密情報及び個人情報が、当社及び当社のグループ会社において共同で利用されること。なお、個人情報の取扱いについては別紙「個人情報の取り扱い」に規定します。</p>

        <p className="content-1-28">
        (2) 本サービスの効果には利用する個人により差があり、当社が本サービスの提供の効果につき一定の客観的効果（Well-Being度 の数値改善を含むが、これに限られません）を保証するものではなく、ユーザーは、本サービスを自己の責任において利用すること。</p>

        <p className="content-1-text">
        (3) 本システムを利用するためには、当社が本システム上または本サービスサイト上等で随時定める端末の種類、 OSおよびソフトウェアを使用することが条件となること。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="content-27">
        第29条 （本サービスの提供の停止等）</p>

        <p className="content-29-1">
        当社は、以下に該当する事由が生じた場合は、本ユーザーに対し、緊急時を除き事前に通知した上、本サービスの提供を一時的に停止することができるものとします。また当該停止によって、当社は本ユーザーに何ら補償をする責任を負いません。</p>

        <p className="content-29-2">
        (1) 本システムの保守のため</p>

        <p className="content-29-3">
        (2) 本システムの仕様変更を行うため</p>

        <p className="content-29-4">
        (3) 天変地変その他非常事態が発生し、または発生するおそれがあることで、本サービスの提供ができなくなった場合</p>

        <p className="content-1-text">
        (4) 本システムまたはこれに接続されるネットワーク回線に障害が発生した場合（当社の帰責事由の有無を問いません）</p>

        <p className="content-1-text">
        (5) 本システムにセキュリティ上の障害またはそのおそれが発生し、本ユーザーの個人情報の保護または他の情報の保護のために本サービスの提供を一時的に停止することが妥当であると判断される場合</p>

        <p className="content-1-text">
        (6) 本サービスまたは本サービスコンテンツの全部または一部の提供が、第三者の知的財産権または他の権利を侵害するという疑義または主張があり、当該侵害もしくはこれに伴う損害の回避、予防または軽減のために必要または相当と判断される場合</p>

        <p className="content-1-text">
        (7) 前各号のほか、当社が、やむを得ない事由により本サービスの提供の一時的な停止が必要と判断した場合</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s6">
        第６章 禁止事項</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="title-1">
        第30条 （権利に関する禁止事項）</p>

        <p className="content-30-1">
        本サービス及び当社の知的財産権、その他権利を侵害する行為に関する禁止事項として、本ユーザーは、以下の各号に該当する事項を遵守するものとします。</p>

        <p className="content-1-text">
        (1) 当社が指定した方法以外で、本サービスの利用をしないこと。また、本サービス及び当社に損害を与えるような本システムの使用のほか、目的や理由を問わず、本システムまたは本サービスコンテンツのあらゆる改変、リバースエンジニアリング（本システムの逆アセンブル、逆コンパイル等の行為を含むがこれらに限られません）を行わないこと</p>

        <p className="content-1-text">
        (2) 本サービスの知的財産権、ノウハウ等を用いて、本サービスと類似のサービス開発、提供を行わないこと</p>

        <p className="content-1-text">
        (3) 本サービスを利用する目的以外に、本サービス（本サービスを通じて開示された当社のアイデアやノウハウ、及び本サービス上のコンテンツを含む。）、及びこれに付随して当社から提供された書類、データ等を利用しないこと</p>

        <p className="content-1-text">
        (4) 本サービスの利用を通じて取得した書類、データ等の一切を第三者に対し、開示、譲渡または貸与しないこと</p>

        <p className="content-1-text">
        (5) 当社または第三者の著作権や商標権、その他知的財産権、肖像権、プライバシー権、パブリシティ権等の正当な権利を侵害しないこと</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="title-1">
        第31条 （本サービスに関する禁止事項）</p>

        <p className="content-31-1">
        本サービスの妨げに関する禁止事項として、本ユーザーは以下の各号に該当する事項を行わないものとします。</p>

        <p className="content-14-2">
        (1) 本システムに悪影響を与える可能性のある一切のプログラムを使用すること</p>

        <p className="content-31-2">
        (2) 本システムへの不正アクセス、また、本システム上に保存されたデータを不正に編集、削除する行為</p>

        <p className="content-1-14">
        (3) Well-Being度 を評価するロジックを解析する等、本サービスに対してリバースエンジニアリングその他の解析を行うこと</p>

        <p className="content-31-4">
        (4) 本システムに過度な負荷をかける行為</p>

        <p className="content-1-12">
        (5) 本サービスを通じて嫌がらせ、スパム送信行為等、第三者に迷惑をかけるおそれがあると当社が判断する行為</p>

        <p className="content-31-6">
        (6) 本サービスに関する ログインID・パスワードの不正使用</p>

        <p className="content-31-7">
        (7) 本ユーザー以外の複数のアカウントを作成した本サービスの利用</p>
        <p className="content-31-8">
        (8) 本サービスの安定的運営に必要であるとの判断に基づく、当社からの指示に従わないこと</p>
        <p className="content-1-12">
        (9) 当社、ソリューションパートナー、他の本サービスを利用する第三者、その他の第三者の権利、利益、名誉等を損ねること。</p>
        <p className="content-14-2">
        (10) 他人のプライバシーを侵害したり、他人を中傷したりすること</p>
        <p className="content-31-8">
        (11) 自分の 
          <span className="s7">Well-Being </span>度向上を願う意欲なく本サービスを利用すること
        </p>
        <p className="content-31-12">
        (12) 本サービスの判定基準や分析データ、世界観、価値観、思想について、公然と侮辱したり、社会的評価を低下させるおそれのある行為をしたりすること</p>
        <p className="content-1-13">
        (13) 他人になりすまして取引を行うこと、虚偽の情報を入力すること、その他の不正行為を行うこと</p>
        <p className="content-14-2">
        (14) 本サービスを無断で営利目的に使用すること</p>
        <p className="content-31-8">
        (15) 法令に違反すること</p>
        <p className="content-31-8">
        (16) 当社が定める各種規約に違反すること</p>
        <p className="content-1-1">
        (17) コンピュータのソフトウェア、ハードウェア、通信機器の機能を妨害、破壊、制限するように設計されたコンピューターウィルス、コンピュータコード、ファイル、プログラム等のコンテンツを本サービス、本システムにアップロードしたり、掲示したり、メール等の手段で公衆送信すること</p>
        <p className="content-29-2">
        (18) 方法の如何を問わず、他の本ユーザーの個人情報を収集したり蓄積したりすること</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s6">
        第７章 反社的勢力の排除</p>

        <p className="space-title">
          <br/>
        </p>

        <p className="content-27">
        第32条 （反社会的勢力の排除条項）</p>

        <p className="content-2-4">
        1 当社及び本ユーザーは、自らもしくは自らの代表者、役員、主要な使用人または実質的な経営権を有するものが次の各号のいずれかにも該当しないことを将来にわたって表明し、保証するものとします。</p>
        <p className="content-32-1">
        (1) 暴力団</p>
        <p className="content-32-2">
        (2) 暴力団員</p>
        <p className="content-32-2">
        (3) 暴力団準構成員</p>
        <p className="content-32-4">
        (4) 暴力団関連企業</p>
        <p className="content-31-8">
        (5) 総会屋等、社会運動等標榜ゴロ、または特殊知能暴力集団等</p>
        <p className="content-31-8">
        (6) その他前各号に準ずる者</p>
        <p className="content-32-6">
        2 当社及び本ユーザーは、自らまたは第三者を利用して、次の各号のいずれかに該当する行為を行わないことを確約するものとします。</p>
        <p className="content-14-2">
        (1) 暴力的な要求</p>
        <p className="content-31-8">
        (2) 法的な責任を超えた不当な要求行為</p>
        <p className="content-31-8">
        (3) 取引に関して、脅迫的な言動をし、または暴力を用いる行為</p>
        <p className="content-1-12">
        (4) 風説を流布し、偽計もしくは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為</p>
        <p className="content-14-2">
        (5) その他前各号に準ずる行為</p>
        <p className="content-32-3">
        3 当社または本ユーザーは、相手方が前２項のいずれかに違反した場合、通知、催告をすることなく本契約を解除することができるものとします。</p>
        <p className="content-32-4-1">
        4 前項の規定により、本契約が解除された場合には、解除された者は、解除により生じる損害について、解除者に対し一切の請求をすることができないものとします。ただし、これにより解除者からの損害賠償の請求を妨げないものとします。</p>
        <p className="content-below">
        【以下余白】</p>
        <p className="content-service">
        別紙「サービス一覧表」</p>
        <p className="space-title">
          <br/>
        </p>
        <table className="content-table" cellSpacing="0">
          <tr style={{ height: '21pt'}}>
            <td style={{ width: '191pt', backgroundColor: "#D9D9D9" }}>
              <p className="s11">
              Happiness Book オプションサービス</p>
            </td>
            <td style={{ width: '89pt', backgroundColor: "#D9D9D9" }}>
              <p className="s12">
              基本利用期間</p>
            </td>
            <td style={{ width: '90pt', backgroundColor: "#D9D9D9" }}>
              <p className="s12">
              料金（税別）</p>
            </td>
          </tr>
          <tr style={{ height: '16pt'}}>
            <td  style={{ width: '191pt'}}>
              <p className="s12 s12-1">
              （空白）</p>
            </td>
            <td style={{ width: '89pt' }}>
              <p className="s12 s12-2">
              （空白）</p>
            </td>
            <td style={{ width: '90pt' }}>
              <p className="s12 s12-3">（空白）</p>
            </td>
          </tr>
        </table>
        <p className="space-title">
          <br/>
        </p>
        <p className="space-title">
          <br/>
        </p>
        <p className="content-above">以上</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="s13">「個人情報の取り扱い」</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="s6">第１章 用語の定義</p>
        <p className="s6-1">第１条 （個人情報の定義）</p>
        <p className="s6-2">「個人情報」とは、個人情報の保護に関する法律（以下「個人情報保護法」といいます）に規定される本サービスを通じて当社が取得したユーザー関連情報のうち、個人情報保護法に規定される個人情報をいいます。</p>
        <p className="s6-3">その他の用語については、この「個人情報の取り扱い」（以下「本ポリシー」といいます）に別段の記載がない限り、Happiness Book 利用規約（以下「利用規約」といいます）の定義によるものとします。</p>
        <p className="space-title">
          <br/>
        </p>

        <p className="s6">
        第２章 個人情報</p>

        <p className="s6-2-title">第 2 条（個人情報の取り扱い）</p>
        <p className="s6-2-1">1 当社は、本ユーザーより取得したユーザー関連情報のうち、個人情報については、本ポリシー及び個人情報保護法その他個人情報の保護に関する法令、ガイドライン等を遵守し、適切に取り扱うものとします。</p>
        <p className="s6-2-2">2 当社は、以下のとおり個人情報の取扱いを行います。本ユーザーは本項の内容に同意をしたうえで本サービスの利用を開始します。</p>
        <p className="content-29-2">(1) 個人情報保護管理者：Social Healthcare Design 株式会社 代表取締役</p>
        <p className="content-29-3">(2) 取得するデータの項目</p>
        <p className="s6-2-3">当社は、本サービスにおいて、次に掲げる個人情報を取得します。</p>
        <p className="s6-2-3">① 本ユーザーの属性情報</p>
        <p className="s6-2-3-1">氏名、住所、電話番号、所属企業等名、所属部署、性別、年齢、生年月日、メールアドレス、アカウント ID 及びパスワードなど</p>
        <p className="content-1-25-2">② 本ユーザーの本サービスに関する情報</p>
        <p className="s6-2-3-2">心身、健康状態、行動分析に関する本ユーザー自身の認識を回答したアンケート情報など</p>
        <p className="s6-2-3">③ サービスのご利用に際して取得する情報</p>
        <p className="s6-2-3-3">クッキー（cookie）等のオンライン上の識別子、ご利用の端末情報、位置情報、閲覧履歴その他のサービスの利用状況（アクセスログ、IP アドレス、ブラウザ情報、ブラウザの言語設定等を含みます）など</p>
        <p className="s6-2-3-4">④ その他の情報</p>
        <p className="content-29-3">(3) 本ユーザーから当社へのお問い合わせ・ご連絡等に関する情報など利用目的</p>
        <p className="s6-2-3-5">① 本サービス提供のため※</p>
        <p className="s6-2-3-6">※所属企業等からの委託に基づき、従業員の皆様が団体として Happiness Book に加入される際には、従業員の皆様の「Well-Being 度」向上をサポートする目的の範囲で、分析結果を、所属企業等に提供させていただきます。</p>
        <p className="content-1-25-2">② 本サービスのサービス向上のため</p>
        <p className="s6-2-3">③ 本サービスについての本ユーザーまたは関係者へのサポート・問い合わせ対応のため</p>
        <p className="s6-2-3-7">④ 当社窓口への問合せその他の連絡の際の本人確認のため、もしくは本サービスの運用上必要な連絡を本ユーザーまたは必要な第三者に取るため</p>
        <p className="content-1-25-2">⑤ 当社の本サービスにかかる規約等の変更等の通知のため</p>
        <p className="s6-2-3">⑥ 当社の新たな商品、サービスに関するご案内、マーケティングのため</p>
        <p className="s6-2-3-8">⑦ 当社または当社と所属グループ及びソリューションパートナーからのメールマガジン、アンケートや広告を含むメール等が配信されるサービスを提供するため（このサービスは本ユーザーが配信の有無を選択できます）</p>
        <p className="s6-2-3-9">⑧ サービスの運営上必要と判断したメール等を配信するため（本ユーザーは配信停止を選択できません）</p>
        <p className="s6-2-3-4">⑨ その他、本ユーザーとの取引を適切かつ円滑に履行するため</p>
        <p className="s6-2-3-5">⑩ 前各号に含まれない目的であって、本ユーザーの個別の同意が得られている目的のため</p>
        <p className="s6-2-3-5">⑪ 前各号に関連・付随した事項のため</p>
        <p className="s6-2-1">3 個人情報の第三者提供は、次に掲げる場合またはあらかじめ本人の同意を得た場合を除き、行いません。</p>
        <p className="s6-2-3-10">① 法令に基づく場合</p>
        <p className="s6-2-3-11">② 人の生命、身体又は財産の保護のために必要がある場合であって、ご本人様の同意を得ることが困難であるとき。</p>
        <p className="s6-2-3-12">③ 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、ご本人様の同意を得ることが困難であるとき。</p>
        <p className="s6-2-3-12">④ 国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
        <p className="s6-2-2">4 前項の規定にかかわらず、当社は、次に掲げる場合、当社が取り扱う本ユーザーの個人情報を、第三者に提供します。</p>
        <p className="s6-2-3-12">① 利用企業等に対して、本ユーザーの Well-Being 度を向上させるため、当該本ユーザーの評価結果、ユーザー関連情報その他の本ユーザーから取得した情報を提供する場合</p>
        <p className="s6-2-3-12">② ソリューションパートナーに対して、本ユーザーの Well-Being 度を向上させるため、当該本ユーザーのユーザー関連情報を第 3 条の規定に従い提供する場合</p>
        <p className="s6-2-2">5 当社は事業運営上、お客様により良いサービスを提供するために業務の一部を外部に委託しています。業務委託先に対しては、個人情報を預けることがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、契約等において個人情報の適正管理・機密保持などによりお客様の個人情報の漏洩防止に必要な事項を取決め、適切な管理を実施させます。</p>
        <p className="s6-2-3-13">6 当社は、以下のとおり個人情報を共同利用します。</p>
        <p className="content-29-3">(1) 共同利用する個人情報は、以下に定める情報とします。</p>
        <p className="s6-2-3-3">本ポリシー2 条 2 項 2 号に列挙した個人情報、Well-Being 度評価及び関連する分析データ、本サービスの提供に関連してご本人様から当社に提供される一切の情報</p>
        <p className="content-29-2">(2) 共同利用の利用目的は、以下のとおりとします。</p>
        <p className="s6-2-3-14">本ユーザーの Well-Being 度向上をサポートする目的において、必要な範囲に限り利用を行います。</p>
        <p className="content-29-2">(3) 共同利用の範囲は、以下のとおりとします。</p>
        <p className="s6-2-3-14">当社のグループ会社（①当社が直接的または間接的にその議決権株式の 50％超の株式を保有する会社、②当社の議決権株式の 50％超の株式を保有する会社、または③ ②の会社が直接的または間接的にその議決権株式の 50％超の株式を保有する会社をいう）。</p>
        <p className="content-29-2">(4) 共同利用の管理責任者は、下記のとおりとします。</p>
        <p className="s6-2-3-15">神奈川県横浜市中区日ノ出町 1 丁目 36 番 404B</p>
        <p className="s6-2-3-15">Social Healthcare Design 株式会社 代表取締役 亀ヶ谷正信</p>
        <p className="content-29-3">(5) 取得方法</p>
        <p className="s6-2-3-16">本サービス上で本ユーザーが登録及び利用した当社の本サービスデータ並びに情報を保管するサーバーにアクセスし、閲覧、ダウンロードまたは他の方法での転送をします。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="s6">第 3 条（ソリューションパートナーへの個人情報の提供）</p>
        <p className="s6-2-1">1 利用規約第 4 条の場合であって、当社が、本ユーザーの同意にもとづきソリューションパートナーへ個人情報を提供する場合、ソリューションパートナーは、本ユーザーの個人情報のうち当社が指定するものの提供を受け、本サービス提供に役立てるために限定して利用することがあります。</p>
        <p className="s6-2-2">2 本ユーザーが、本サービスを、ソリューションパートナーが提供するサービス（カウンセリングサービスが含まれるが、これに限らない）の一部として利用を希望する場合には、当社は、評価結果およびユーザー関連情報の全部または一部を、ソリューションパートナーに対し、他の形式または個人が特定されないよう加工された内容で提供する必要があり、本ユーザーは予めこれを承諾します。</p>
        <p className="s6-2-2">3 当社は、本ユーザーの個人情報に関して、ソリューションパートナーから API 連携等により提供を受ける場合があり、本ユーザーは予めこれを承諾します。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">第 4 条（集計等データの利用等）</p>
        <p className="s6-2-1">1 当社は、ユーザー関連情報について、個人が特定されないよう加工もしくは集計したもの（加工もしくは集計については、他の情報と合わせることを含みます）またはこれらを、場合により他の情報とあわせて分析、解析、比較、評価もしくは研究した知見、データ、ノウハウもしくは著作物の総称もしくはその一部を、以下に例示される目的で利用する場合があり、本ユーザーはこれに異議を唱えません。</p>
        <p className="s6-2-3-17">(1) 本サービスの利用動向その他の現状分析に利用すること</p>
        <p className="s6-2-3-18">(2) 本サービスの改良、拡張、または修正のために利用すること</p>
        <p className="content-1-1">(3) 当社または第三者による、本サービス以外の他のサービスもしくはテクノロジーの開発と運用のために利用すること</p>
        <p className="content-1-text">(4) 有償か無償かを問わず、ヘルスケア、人事労務、技術開発、政策立案・実行、社会福祉、学術研究、その他の活動を行う企業等と共有もしくはこれらに対して提供をし、企業等が任意の目的で使用することを許すこと（使用の結果を論文その他の形で公表することを含みます）</p>
        <p className="content-1-text">(5) 有償か無償かを問わず、前号と同様の目的またはその他の目的のために多くの企業等の利用に供するためのナレッジベース、データベース、または基盤技術の開発、運用、提供のため</p>
        <p className="content-29-2">(6) 前各号に関連・付随した事項のため</p>
        <p className="s6-2-1">2 本条の規定は、当社と本ユーザーとの本契約、または本ユーザーによる本サービスの利用が終了した後も有効に存続します。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">第 5 条（安全管理措置）</p>
        <p className="s6-2-3-19">当社は、その取り扱う個人情報の漏えい、滅失又はき損の防止その他個人情報の安全管理のために、必要かつ適切な措置を講じます。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">第 6 条（個人情報の利用中止等）</p>
        <p className="s6-2">本ユーザーは、別途当社が定める手続に従い、個人情報保護法の定めに基づき開示等（利用目的の通知、開示、訂正・追加・削除、利用の停止・第三者提供の停止の請求をいい、以下「開示等」といいます）を請求することができます。その場合、当社は、個人情報保護法その他の法令に従い適切に対応いたします。なお、当社が開示等の義務を負わない場合は、開示等を行わないことがございます。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">第 7 条（本ポリシーの変更）</p>
        <p className="s6-2-3-19">当社は、個人情報の利用目的を、関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には本ユーザーに通知又は公表します。</p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">第 8 条（本サービスの基盤となるサービスの規約）</p>
        <p className="s6-2">本ユーザーは、本サービスにおいて、サーバー・ネットワーク基盤として「Amazon ウェブ Services」を使用することを了承します。Amazon ウェブ Services のプライバシー規約については、以下のものが該当します。ただし、当社は、本サービスの運営の都合上、サーバー・ネットワーク基盤を随時変更することがあります。</p>
        <p  className="s6-2-3-20">
          <a href="https://aws.amazon.com/jp/privacy/" className="s8 text-link" target="_blank">Amazon ウェブ Services のプライバシー規約：</a>
          <a href="https://aws.amazon.com/jp/privacy/" className="s14 text-link" target="_blank">https://aws.amazon.com/jp/privacy/</a>
        </p>
        <p className="space-title">
          <br/>
        </p>
        <p className="title-1">第 9 条（お問い合わせ）</p>
        <p className="s6-2-3-19">ご意見、ご質問、苦情、開示等のお申出その他個人情報の取扱いに関するお問い合わせは、下記の窓口までお願いいたします。</p>
        <p className="s6-2-3-21">〒231-0066 神奈川県横浜市中区日ノ出町 1 丁目 36 番 404B</p>
        <p className="s6-2-3-21">Social Healthcare Design 株式会社</p>
        <p className="s6-2-3-22">
          <a href="mailto:support@s-h-d.co.jp" className="s8 text-link" target="_blank">お客様相談窓口 E-mail：support@s-h-d.co.jp</a>
        </p>
        <p className="space-title"> <br/> </p>
      </div>
  )
}

const TermAndService = ({ onNavigate }: Props) => {
  const [numPages, setNumPages] = useState(0)
  const [isAgree, setIsAgree] = useState(false)
  const [isTerm, setIsTerm] = useState(false)
  const [loading, setLoading] = useState(true)

  function onDocumentLoadSuccess({ _pdfInfo: { numPages: numOfPages } }: any) {
    setNumPages(numOfPages)
    // setTimeout(() => {
    // setLoading(false)
    // }, 200)
  }

  const handleChangeCheckbox = (name: string) => (e: any) => {
    if (name === 'term') {
      setIsTerm(e.target.checked)
    }
    if (name === 'agree') {
      setIsAgree(e.target.checked)
    }
  }

  const handleSubmit = () => {
    if (isTerm && isAgree) {
      onNavigate()
    }
  }

  const loadingPdf = () => {
    return <HashLoader color={'#56ac8a'} loading={loading} css={override} size={150} />
  }

  return (
    <>
      {htmlTermAndService()}
      <div className="container-choice">
        <div className="choice-option">
          <div className="container-button">
            <input type="checkbox" id="checkbox-term" checked={isTerm} onChange={handleChangeCheckbox('term')} />
            <label className="title-option" htmlFor="checkbox-term">{SURVEY.TERM_CONDITION}</label>
          </div>
          <div className="container-button">
            <input type="checkbox" id="checkbox-agree" checked={isAgree} onChange={handleChangeCheckbox('agree')} />
            <label className="title-option" htmlFor="checkbox-agree">{SURVEY.AGREE}</label>
          </div>
        </div>
        <button
          className={isTerm && isAgree ? 'button-agree' : 'button-agree disabled-btn'}
          onClick={handleSubmit}
          disabled={!(isTerm && isAgree)}
        >
          {SURVEY.SUBMIT}
        </button>
      </div>
    </>
  )
}

export default TermAndService
