import { Modal, Button } from 'react-bootstrap'

type IDialogDeleteUser = {
  show: boolean
  onHide: () => void
  onSubmit: () => void
}

const DialogDeleteUser = ({ show, onHide, onSubmit }: IDialogDeleteUser) => {
  const handleSubmit = () => {
    onSubmit()
    onHide()
  }
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header><h4>アカウント削除</h4></Modal.Header>
      <Modal.Body>
        <p className="text-confirm mb-1">アカウントを削除すると、いままでの記録がすべて削除され、ログインできなくなります。</p>
        <p className="text-confirm mb-1">また、再度同じメールアドレスで登録されても復旧することはできません。</p>
        <p className="text-confirm mb-1">それでもよろしければ「削除する」をタップしてあなたに関する情報をすべて削除してください。</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <Button onClick={handleSubmit} className="confirm">
          削除する
        </Button>
        <Button onClick={onHide} className="cancel btn-dark">
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DialogDeleteUser
