/* eslint-disable import/no-anonymous-default-export */
import { Location } from 'history'
// import sanitizeHtml from 'sanitize-html'
/**
 * Returns x raised to the n-th power.
 *
 * @param {Location} location for rerender when has auth.
 */
const checkAuth = (location?: Location) => {
  const token = sessionStorage.getItem('jwtToken') ?? localStorage.getItem('jwtToken')
  return token ? token : true
}

export const checkArray = (value: any) => {
  return value && Array.isArray(value) && value.length > 0
}

export const checkObject = (objCheck: any) => {
  return objCheck && Object.keys(objCheck).length > 0 && objCheck.constructor === Object
}

export const compareDateNotify = (interval: any, timeSub: any) => {
  if (interval === 2) {
    return new Date().getTime() > new Date(timeSub).getTime()
  }
  return false
}

export const generateDateNotify = () => {
  const date = new Date()
  return date.setMinutes(date.getMinutes() + 5)
}

export const countContentChecked = (objCount: any) => {
  let done = true
  if (objCount) {
    for (const keyCount in objCount) {
      if (objCount.hasOwnProperty(keyCount)) {
        if (!objCount[keyCount].status) {
          done = false
          break
        }
      }
    }
  }
  return done
}

export const searchByKeyword = (obj: any, keyword: string) => {
  return obj.filter((item: any) => {
    return Object.values(item)
      .map((a: any) => {
        return checkObject(a) ? Object.values(a).join(',').toLowerCase() : a
      })
      .join(',')
      .toLowerCase()
      .includes(keyword)
  })
}

const convertDataListContent = (data: any) => {
  const listContentFromApi = []
  for (const value of Object.values(data.section)) {
    listContentFromApi.push(value)
  }
  for (const value of Object.values(data.image_background)) {
    listContentFromApi.push(value)
  }
  for (const value of Object.values(data.text_only)) {
    listContentFromApi.push(value)
  }
  for (const value of Object.values(data.image_text)) {
    listContentFromApi.push(value)
  }
  for (const value of Object.values(data.image_only)) {
    listContentFromApi.push(value)
  }
  for (const value of Object.values(data.question_answer_data)) {
    listContentFromApi.push(value)
  }
  return listContentFromApi.sort((a: any, b: any) => a.priority - b.priority)
}

// const convertTagToName = (text: any) => {
//   return (
//     text &&
//     sanitizeHtml(text.replace('<name></name>', sessionStorage.getItem('full_name')!), {
//       allowedAttributes: {
//         a: ['href', 'style'],
//       },
//     })
//   )
// }

const generateParamPayload = (payload: any) => {
  let paramsPayload = ''
  for (const keyPayload in payload) {
    if (payload.hasOwnProperty(keyPayload)) {
      paramsPayload += `${keyPayload}=${payload[keyPayload]}&`
    }
  }
  return paramsPayload
}

const getNumberPeople = (numberPeople: number) => {
  return numberPeople > 9999 ? 9999 + '+' : numberPeople
}

const scrollToBottom = (idElement: string) => {
  const containerForm = document.getElementById(idElement)!
  if (containerForm) {
    containerForm.scrollTop = containerForm.scrollHeight
  }
}

const getParamsHasCompany = (_company: number | string, params: any) => {
  if (!_company || _company === 'all') {
    return params
  }
  return { ...params, company_id: _company }
}

const hasClass = (el: any, className: string) => {
  if (!el) {
    return
  }

  return el.classList
    ? el.classList.contains(className)
    : new RegExp('\\b' + className + '\\b').test(el.className)
}

const isNumber = (n: string) => /^-?[\d.]+(?:e-?\d+)?$/.test(n);

export const toASCII = (chars: string) => {
  let ascii = '';
  for (let i = 0, l = chars.length; i < l; i++) {
    let c = chars[i].charCodeAt(0);

    // make sure we only convert half-full width char
    if (c >= 0xff00 && c <= 0xffef) {
      c = 0xff & (c + 0x20);
    }

    ascii += String.fromCharCode(c);
  }

  return ascii;
};


export default {
  checkAuth,
  checkArray,
  searchByKeyword,
  convertDataListContent,
  countContentChecked,
  // convertTagToName,
  generateParamPayload,
  getNumberPeople,
  scrollToBottom,
  getParamsHasCompany,
  hasClass,
  isNumber
}
