import { Modal, Button } from 'react-bootstrap'

type IPropsDialogInfo = {
  show: boolean
  onHide: () => void
  onSubmit: () => void
  companyName?: string
}

const DialogInfo = ({ show, onHide, onSubmit, companyName }: IPropsDialogInfo) => {
  const handleSubmit = () => {
    onSubmit()
    onHide()
  }
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg" backdrop="static"keyboard={false}>
      <Modal.Body>
        <p className="company">{companyName}の会員としてアカウントを登録します。</p>
      </Modal.Body>
      <Modal.Footer>
        <div  className="flex justify-center w-100">
          <Button onClick={handleSubmit} className="confirm w-1/3">
            次へ
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default DialogInfo
