import { Modal, Button } from 'react-bootstrap'

type IPropsDialogInfo = {
  show: boolean
  onHide: () => void
  email?: string
}

const DialogInfoSendMail = ({ show, onHide, email }: IPropsDialogInfo) => {
  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Body>
        <p className="company">受付が完了しました</p>
        <p className="text-confirm">
        健幸度測定と分析の最新結果をPDFファイルとして{email}メールアドレスに送らせていただきました。メールのご確認をお願いいたします。
        <br />【最大１日程度お時間がかかる場合があります】
        </p>
      </Modal.Body>
      <Modal.Footer className="text-center justify-content-center">
        <Button onClick={onHide} className="cancel">
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DialogInfoSendMail
