import { useState } from 'react'
import { Login } from '../Survey/listQuestion-api'
import { Link, useHistory } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { LOGIN } from '../../lang/Survey.lang'
import '../Survey/survey.scss'
import { useToasts } from 'react-toast-notifications';
import DialogInfoUser from './dialogInfoUser';

const LoginPage = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const [isShowMessageError, setShowMessageError] = useState(false);
  const [isShowPopup, setShowPopup] = useState(false);
  const [username, setUsername] = useState('');

  const handleSubmit = async (values: any) => {
    try {
      const dataLogin = {
        email: values.email,
        password: values.password,
      }
  
      const res = await Login(dataLogin);
      if (res?.data?.token) {
        localStorage.setItem('jwtToken', res?.data?.token);
        localStorage.setItem('username', res?.data?.profile?.username);
        localStorage.setItem('nickname', res?.data?.profile?.nickname);
        localStorage.setItem('email', res?.data?.profile?.email);
        localStorage.setItem('userId', res?.data?.profile?.id);
        localStorage.setItem('companyCode', res?.data?.profile?.company?.company_code);
        localStorage.setItem('isAnswer', res.data.is_answer.toString());
        setUsername(res?.data?.profile?.username);
        history.push('/home');
      } else {
        setShowMessageError(true);
      }
    } catch (error: any) {
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }
  
  const validationSchemaForm = Yup.object({
    email: Yup.string()
    .max(254, 'メールアドレスは長いすぎて、登録できません。')
    .required('入力必須です。')
    .matches(
      /^[^\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
      'メールアドレスに不正な文字が含まれています。',
    )
    .email('正しいメールアドレスを入力してください。'),
    password: Yup.string()
      .min(8, '８文字以上のパスワードを入力してください。')
      .required('入力必須です。')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!-@#$%^&*?]{8,50}$/,
        '半角の英大文字、英小文字、数字の組み合わせが必要です。',
      ),
  })

  return (
    <div className="app">
      <DialogInfoUser
        show={isShowPopup}
        username={username}
      />
      <div id="wrapper">
        <h1 className="infomation text-center">{LOGIN.TITLE}</h1>
        <Formik
          enableReinitialize
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchemaForm}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="form-group">
                <label>{LOGIN.EMAIL}</label>
                <Field
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-describedby="emailHelp"
                  placeholder={LOGIN.EMAIL_PLACEHOLDER}
                />
                <p className="error text-red"><ErrorMessage name="email" /></p>
              </div>
              <div className="form-group">
                <label>{LOGIN.PASSWORD}</label>
                <Field
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  aria-describedby="passwordHelp"
                  placeholder={LOGIN.PASSWORD_PLACEHOLDER}
                />
                <p className="error text-red"><ErrorMessage name="password" /></p>
              </div>
              {isShowMessageError && (<p className="error text-red">メールアドレスまたはパスワードが正しくありません</p>)}
              <div className="flex justify-between items-center">
                <div className="w-2/12" />
                <div className="flex justify-center pl-16 w-6/12">
                  <button
                    type="submit"
                    className={Object.keys(errors).length > 0 ? 
                      "px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                      : "text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                    disabled={Object.keys(errors).length > 0}
                  >
                    {LOGIN.SUBMIT}
                  </button>
                </div>
                <div className="w-4/12 text-right">
                  <p className="m-0 pb-2 font-14"><Link className="text-primary font-bold" to="/forgot-password">{LOGIN.FORGOT_PASSWORD_LINK}</Link></p>
                  <p className="m-0 font-14"><Link className="text-primary font-bold" to="/signup">{LOGIN.REGISTER_LINK}</Link></p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
export default LoginPage
