import { combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter } from 'connected-react-router'
import { AuthReducer } from './_auth-redux'

const rootReducer = (history: History) =>
  combineReducers({
    auth: AuthReducer,
    router: connectRouter(history),
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
