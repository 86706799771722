/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from 'react'
import { Redirect, Switch, Route, useLocation } from 'react-router-dom'
import AuthLayout from '../layouts/AuthLayout'
import ErrorsPage from '../containers/NotFound/ErrorsPage'
import utils from '../utils'
import BaseLayout from '../layouts/BaseLayout'

export function Routes() {
  const location = useLocation()
  return (
    <Switch>
      {!utils.checkAuth(location) ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <AuthLayout />
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />

      {!utils.checkAuth(location) ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <BaseLayout />
      )}
    </Switch>
  )
}
