import { useState } from 'react'
import { sendEmailToUserApi } from '../Survey/listQuestion-api'
import { useHistory } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css';
import '../Survey/survey.scss'
import { useToasts } from 'react-toast-notifications';
import DialogInfoSendMail from '../Infomation/dialogInfoSendMail';
import Header from './Header';
import { htmlTermAndService } from '../TermAndService';

const TermAndService = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showMessageSendMailSuccess, setShowMessageSendMailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmailToUser = async () => {
    try {
      setLoading(true)
      const checkUserAnswer = localStorage.getItem('isAnswer');
      if (!checkUserAnswer || checkUserAnswer === 'false') {
        console.log(checkUserAnswer)
        addToast('アンケートを回答しないとWell-Being評価できません。アンケートの回答を開始してください。', { appearance: 'error' });
        return;
      }
      const checkEmailExist = localStorage.getItem('email');
      if (!checkEmailExist) {
        addToast('メールアドレスが保存されていません。', { appearance: 'error' });
        return;
      }
      await sendEmailToUserApi({email: [checkEmailExist]});
      setLoading(false);
      setShowMessageSendMailSuccess(true);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  return (
    <div className="app p-0">
      <div className={loading ? 'background-drop' : ''} id="outer-container">
        <Header showMenu={showMenu} setShowMenu={setShowMenu} sendEmailToUser={sendEmailToUser} />
        <DialogInfoSendMail
          show={showMessageSendMailSuccess}
          onHide={() => setShowMessageSendMailSuccess(false)}
          email={localStorage.getItem('email') ?? ''}
        />
        <main id="page-wrap">  
          <div className="header bg-blue-300 text-center text-2xl text-white position-relative">
            <div className="position-absolute">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => setShowMenu(true)}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <span>Happiness Book Web</span>
            <div className="position-absolute button-back-home">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => history.push('/home')}>
                トップへ
              </button>
            </div>
          </div>
          <div className="content-list">
            {htmlTermAndService()}
          </div>
        </main>
        
      </div>
    </div>
  )
}
export default TermAndService;
