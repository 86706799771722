import ReactDOM from 'react-dom'
import './assets/styles/index.scss'
import App from './App'
import configureStore from './redux/index'
import reportWebVitals from './reportWebVitals'
import 'bootstrap/dist/css/bootstrap.min.css'
import { MetronicSplashScreenProvider } from './layouts/MetronicSplashScreen'

const store = configureStore()
const { PUBLIC_URL } = process.env

ReactDOM.render(
  <MetronicSplashScreenProvider>
    <App store={store} basename={PUBLIC_URL} />
  </MetronicSplashScreenProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
