import { Modal, Button } from 'react-bootstrap'

type IPropsDialogInfo = {
  show: boolean
  onHide: () => void
  email: string
}

const ReSendCodeModal = ({ show, onHide, email }: IPropsDialogInfo) => {

  return (
    <Modal show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Body>
        <p className="company">{email}に認証コードを再送しました。</p>
      </Modal.Body>
      <Modal.Footer>
        <div  className="flex justify-center w-100">
          <Button onClick={onHide} className="confirm w-1/3">
            OK
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ReSendCodeModal
