import { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import { Register, checkAuthCode, checkCompany, checkEmail, getProfile } from '../Survey/listQuestion-api'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useToasts } from 'react-toast-notifications'
import { LOGIN, INFO, SURVEY, SIGN_UP } from '../../lang/Survey.lang'
import '../Survey/survey.scss';
import TermAndService from '../TermAndService';
import { ICompany } from '../Survey/questionType';
import DialogInfo from './dialogInfo';
import ModalUserExist from './ModalUserExist';
import ReSendCodeModal from './ReSendCodeModal';

const STEP_REGISTER = {
  policy: 1,
  info: 2,
  password: 3,
  code: 4,
  success: 4
}

const currentYear = moment().year() - 10 + ''

const SignUpPage = () => {
  const { addToast } = useToasts()
  const history = useHistory();
  const [display, setDisplay] = useState(1);
  const [userInfo, setUserInfo] = useState({
    id: 0,
    email: '',
    password: '',
    username: '',
    birthday: '',
    gender: 1,
    company_code: '',
    confirm_password: '',
    code: '',
  });
  const [companyInfo, setCompanyInfo] = useState<ICompany>()
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalCompany, setShowModalCompany] = useState<boolean>(false);
  const [showModalResendCode, setShowModalResendCode] = useState<boolean>(false);

  const [showMessageError, setShowMessageError] = useState('');
  const [isShowExistEmail, setShowExistEmail] = useState(false);
  const [month, setMonth] = useState('1');
  const [year, setYear] = useState(currentYear);
  const [isShowPassword, setShowPassword] = useState(false);

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const companyLoad = useCallback(async () => {
    if (params && params.get('cc')) {
      let companyCode = params.get('cc') || '';
      try {
        if (companyCode && companyCode !== '') {
          const res = await checkCompany({ "company_code": companyCode.toString() })
          if (res.data?.id) {
            setCompanyInfo(res.data)
          }
        }
      } catch (error: any) {
        console.log('companyLoad: ', error)
      }
    }
  }, []);

  useEffect(() => {
    companyLoad()
  }, [])

  const handleSubmit = async (values: any, isReSendCode: boolean) => {
    try {
      const dataRegister = {
        email: values.email,
        password: values.password,
        username: values.username,
        gender: values.gender,
        birthday: values.birthday,
        company: companyInfo?.id ?? 1,
        nickname: values.username,
      }
  
      const res = await Register(dataRegister);
      if (res?.data?.id) {
        localStorage.setItem('username', res?.data?.profile?.username);
        localStorage.setItem('userId', res?.data?.profile?.id);
        localStorage.setItem('companyCode', res?.data?.profile?.company?.company_code);
        localStorage.setItem('isNewUser', 'true');
        if (isReSendCode) {
          setShowModalResendCode(true)
        }
        setDisplay(STEP_REGISTER.code);
        setShowMessageError('');
        setUserInfo({
          ...userInfo,
          id: res.data.id,
          password: values.password,
        })
      } else if (res?.error_code > 0) {
        setShowMessageError(res.message);
      }
    } catch (error: any) {
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  const handleSubmitCode = async (values: any) => {
    try {
      if (userInfo.id > 0) {
        const res = await checkAuthCode({
          user: userInfo.id,
          otp_code: values.code,
        });
        if (res.data?.token) {
          await localStorage.setItem('jwtToken', res.data.token);
          const userInfo = await getProfile();
          if (userInfo?.data?.id) {
            localStorage.setItem('jwtToken', res?.data?.token);
            localStorage.setItem('username', res?.data?.profile?.username);
            localStorage.setItem('nickname', res?.data?.profile?.nickname);
            localStorage.setItem('email', res?.data?.profile?.email);
            localStorage.setItem('userId', res?.data?.profile?.id);
            localStorage.setItem('companyCode', res?.data?.profile?.company?.company_code);
            localStorage.setItem('isAnswer', res.data.is_answer.toString());
          }
          history.push('/home');
        } else if (res?.error_code > 0) {
          setShowMessageError(res.message);
        }
      } 
    } catch (error: any) {
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }
  
  const validationSchemaFormInfo = Yup.object({
    username: Yup.string()
    .max(254, 'メールアドレスは長いすぎて、登録できません。')
    .required('入力必須です。'),
    email: Yup.string()
    .max(254, 'メールアドレスは長いすぎて、登録できません。')
    .required('入力必須です。')
    .matches(
      /^[^\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
      'メールアドレスに不正な文字が含まれています。',
    )
    .email('正しいメールアドレスを入力してください。'),
    company_code: Yup.string().matches(/^[0-9０-９]{6}$/, 
      '６桁の数字を入力してください。BAで始まる組織コードをお持ちの方は、先頭のBAは入力せずBAの後の6桁の数字のみご入力ください。'),
  });

  const validationSchemaFormPassword = Yup.object({
    password: Yup.string()
      .min(8, '８文字以上のパスワードを入力してください。')
      .required('入力必須です。')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!-@#$%^&*?]{8,50}$/,
        '半角の英大文字、英小文字、数字の組み合わせが必要です。',
      ),
    confirm_password: Yup.string().required('入力必須です。').oneOf([Yup.ref('password')], 'パスワードが一致していません。'),
  });

  const validationSchemaFormConfirmCode = Yup.object({
    code: Yup.string().required('入力必須です。'),
  });

  const handleSelect = (event: any) => {
    const { name, value } = event.target
    if (name === 'month') {
      setMonth(value)
    } else if (name === 'year') {
      setYear(value)
    }
  }

  const onCheckUserExist = async (event: any) => {
    try {
      if (event.target.value) {
        const emailUser = await checkEmail({email: event.target.value})
        if (emailUser?.data?.exist) {
          setShowExistEmail(true);
        } else {
          setShowExistEmail(false);
        }
      }
      
    } catch (error) {
      console.log('onCheckUserExist: ', error)
    }
  }

  return (
    <div className="app">
      <DialogInfo
        show={showModalCompany}
        onHide={() => setShowModalCompany(false)}
        onSubmit={() => setDisplay(STEP_REGISTER.password)}
        companyName={companyInfo?.name}
      />
      <ModalUserExist
        show={showModal}
        onHide={() => setShowModal(false)}
      />
      <ReSendCodeModal
        show={showModalResendCode}
        onHide={() => setShowModalResendCode(false)}
        email={userInfo.email}
      />
      {display === STEP_REGISTER.policy && <TermAndService onNavigate={() => setDisplay(STEP_REGISTER.info)} />}
      {display === STEP_REGISTER.info && (
        <div id="wrapper">
        <h1 className="infomation text-center">{SIGN_UP.TITLE}</h1>
          <Formik
            enableReinitialize
            initialValues={userInfo}
            onSubmit={async (values) => {
              try {
                if (params.get('cc') === null && companyInfo?.id === undefined) {
                  const res = await checkCompany({ "company_code": values?.company_code !== '' ? values.company_code : '267767' });
                  if (res.data?.id) {
                    setCompanyInfo(res.data);
                  } else {
                    return;
                  }
                }
              } catch (error: any) {
                addToast(error?.response.data.message.toString(), { appearance: 'error' })
                return
              }
              if (isShowExistEmail) {
                setShowModal(true);
                return;
              }
              let companyCode = '267767';
              if (params.get('cc') !== null && companyInfo?.company_code) {
                companyCode = companyInfo.company_code.toString()
              } else if (params.get('cc') === null && values.company_code !== '') {
                companyCode = values.company_code
              }
              setUserInfo({
                ...userInfo,
                email: values.email,
                gender: values.gender,
                company_code: companyCode,
                birthday: `${year}-${month}-01`,
                username: values.username,
              });
              setShowModalCompany(true);
            }}
            validationSchema={validationSchemaFormInfo}
          >
            {({ errors, touched }) => (
              <Form>
                {companyInfo && companyInfo.company_code && params.get('cc') !== null && (
                  <>
                    <div className="form-group">
                      <label>組織名（組織コード）</label>
                    </div>
                    <div className="form-group">
                      <label>
                        {companyInfo.name} （{companyInfo.company_code}）
                      </label>
                    </div>
                  </>
                )}
                <div className="form-group">
                  <label>{INFO.EMAIL}</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    onBlur={(event: any) => onCheckUserExist(event)}
                    placeholder={INFO.ENTER_EMAIL}
                  />
                  {errors.email && (<p className="error text-red"><ErrorMessage name="email" /></p>)}
                  {isShowExistEmail && <p className="error text-red">すでに登録済のメールアドレスです。</p>}
                </div>
                <div className="form-group">
                  <label>{INFO.NAME}</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="username"
                    name="username"
                    aria-describedby="usernameHelp"
                    placeholder={INFO.ENTER_NAME}
                  />
                  <p className="error text-red"><ErrorMessage name="email" /></p>
                </div>
                <div className="form-group">
                  <label>{INFO.DOB}</label>
                  <div className="birthday-picker">
                    <label htmlFor="year" className="mr-2">
                      <select
                        className="form-select border p-1 rounder" 
                        name="year"
                        id="year"
                        onChange={handleSelect}
                        value={year}
                      >
                        {Array.from(
                          { length: 101 },
                          (_, index: number) => +currentYear - 100 + index
                        ).map((_year) => {
                          return (
                            <option value={_year} key={_year}>
                              {_year}
                            </option>
                          )
                        })}
                      </select>
                      年
                    </label>
                    <label htmlFor="month">
                      <select
                        className="form-select border p-1 rounder"
                        name="month"
                        id="month"
                        value={month}
                        onChange={handleSelect}
                      >
                        {Array.from({ length: 12 }, (_, index: number) => index + 1).map((_month) => (
                          <option value={_month} key={_month}>
                            {_month}
                          </option>
                        ))}
                      </select>
                      月
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="gender">{INFO.GENDER}</label>
                  <Field as="select"
                    className="form-control"
                    id="gender"
                    name="gender"
                  >
                    <option value={1}>{INFO.MALE}</option>
                    <option value={2}>{INFO.FEMALE}</option>
                    <option value={3}>{INFO.OTHER}</option>
                  </Field>
                </div>
                {params.get('cc') === null && (
                  <>
                    <div className="form-group">
                      <label>{INFO.COMPANY}</label>
                      <Field
                        type="text"
                        className="form-control border-b"
                        id="company_code"
                        name="company_code"
                        placeholder={INFO.ENTER_COMPANY}
                      />
                      <p className="error text-red"><ErrorMessage name="company_code" /></p>
                    </div>
                  </>
                )}
                <div className="flex flex-col">
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 
                        "w-40 px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                        : "w-40 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {SURVEY.SUBMIT}
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="m-0"><Link className="text-primary font-bold" to="/survey-login">{LOGIN.LOGIN_LINK}</Link></p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {display === STEP_REGISTER.password && (
        <div id="wrapper">
        <h1 className="infomation text-center">{INFO.INFOMATION}</h1>
          <Formik
            enableReinitialize
            initialValues={userInfo}
            onSubmit={(values) => handleSubmit(values, false)}
            validationSchema={validationSchemaFormPassword}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="password">{LOGIN.PASSWORD}</label>
                  <Field
                    type={isShowPassword ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    name="password"
                    aria-describedby="passwordHelp"
                    placeholder={LOGIN.PASSWORD_PLACEHOLDER}
                  />
                  <p className="error text-red"><ErrorMessage name="password" /></p>
                </div>
                <div className="form-group">
                  <label htmlFor="confirm_password">{LOGIN.RE_PASSWORD}</label>
                  <Field
                    type={isShowPassword ? 'text' : 'password'}
                    className="form-control"
                    id="confirm_password"
                    name="confirm_password"
                    aria-describedby="confirmPasswordHelp"
                    placeholder={LOGIN.RE_PASSWORD_PLACEHOLDER}
                  />
                  <p className="error text-red"><ErrorMessage name="confirm_password" /></p>
                </div>
                {showMessageError !== '' && (<p className="error text-red">{showMessageError}</p>)}
                <div className="flex justify-between items-center">
                  <div className="w-2/12" />
                  <div className="flex justify-center pl-16 w-6/12">
                    <button
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 
                        "px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                        : "text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {SURVEY.SUBMIT}
                    </button>
                  </div>
                  <div className="w-4/12 text-right">
                    <div className="container-button">
                      <input type="checkbox" className="show-password" checked={isShowPassword} onChange={() => setShowPassword(!isShowPassword)} />
                      <label className="title-option" htmlFor="checkbox-agree">{LOGIN.SHOW_PASSWORD}</label>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {display === STEP_REGISTER.code && (
        <div id="wrapper">
        <h1 className="infomation text-center">{LOGIN.CODE_CONFIRM}</h1>
          <Formik
            enableReinitialize
            initialValues={userInfo}
            onSubmit={handleSubmitCode}
            validationSchema={validationSchemaFormConfirmCode}
          >
            {({ errors }) => (
              <Form>
                <div className="form-group">
                  <span>メールアドレス「{userInfo.email}」に認証コードをお送りしました。</span><br />
                  <span>メールに記載されている認証コードを入力してください。</span><br />
                  <span>登録されたメールアドレスが間違っていることに気づいた方は、<span className="link-opacity-100 text-link cursor-pointer" onClick={() => setDisplay(STEP_REGISTER.info)}>ここをクリック</span>して再度、登録しなおしてください。</span><br />
                  <span>メールアドレスが正しい場合には、迷惑メールフォルダや、受信フィルター設定などをご確認ください。</span><br />
                  <span>10分経っても認証コードが見つからない場合には、<span className="link-opacity-100 text-link cursor-pointer" onClick={() => handleSubmit(userInfo, true)}>ここをクリック</span>して認証コードを再送してください。</span><br />
                </div>
                <div className="form-group">
                  <label htmlFor="code">{LOGIN.CODE}</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="code"
                    name="code"
                    aria-describedby="codeHelp"
                    placeholder={LOGIN.CODE_CONFIRM}
                  />
                  <p className="error text-red"><ErrorMessage name="code" /></p>
                </div>
                <div className="flex justify-between">
                  <div className="w-200">
                    <button
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 
                        "px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                        : "text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {SURVEY.SUBMIT}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  )
}
export default SignUpPage
