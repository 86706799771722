import { Modal, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

type IPropsDialogInfo = {
  show: boolean
  username: string;
}

const DialogInfoUser = ({ show, username }: IPropsDialogInfo) => {
  const history = useHistory();

  return (
    <Modal show={show} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Body>
        <p className="company">{username}に回答すると、健幸度®︎分析結果を見ることができます。</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => history.push('/survey')} className="confirm">
          回答を開始する
        </Button>
        <Button onClick={() => history.push('/home')} className="cancel">
          あとで回答する
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DialogInfoUser
