import { useState, useEffect } from 'react'
import { changePassword, sendEmailToUserApi } from '../Survey/listQuestion-api'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup';
import { CHANGE_PASSWORD, FORGOT_PASSWORD, LOGIN } from '../../lang/Survey.lang'
import '../Survey/survey.scss'
import Header from './Header';
import DialogInfoSendMail from '../Infomation/dialogInfoSendMail';

const ChangePasswordPage = () => {
  const { addToast } = useToasts();
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const [isShowPassword, setShowPassword] = useState(false);
  const [showMessageSendMailSuccess, setShowMessageSendMailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      history.push('/survey-login');
    }
  }, [history]);

  const sendEmailToUser = async () => {
    try {
      setLoading(true)
      const checkUserAnswer = localStorage.getItem('isAnswer');
      if (!checkUserAnswer || checkUserAnswer === 'false') {
        console.log(checkUserAnswer)
        addToast('アンケートを回答しないとWell-Being評価できません。アンケートの回答を開始してください。', { appearance: 'error' });
        return;
      }
      const checkEmailExist = localStorage.getItem('email');
      if (!checkEmailExist) {
        addToast('メールアドレスが保存されていません。', { appearance: 'error' });
        return;
      }
      await sendEmailToUserApi({email: [checkEmailExist]});
      setLoading(false);
      setShowMessageSendMailSuccess(true);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  const handleSubmitChangePassword = async (values: any) => {
    try {
      const dataResetPassword = {
        old_password: values.old_password,
        new_password: values.password,
      }

      const res = await changePassword(dataResetPassword);
      if (res?.error_code === 0) {
        addToast(res?.data?.response?.data?.message ?? 'パスワードを更新しました。', { appearance: 'success' });
        localStorage.clear();
        history.push('/survey-login')
      } 
    } catch (error: any) {
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  const validationSchemaChangePasswordForm = Yup.object({
    old_password: Yup.string()
    .min(8, '８文字以上のパスワードを入力してください。')
    .required('入力必須です。')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!-@#$%^&*?]{8,50}$/,
      '半角の英大文字、英小文字、数字の組み合わせが必要です。',
    ),
    password: Yup.string()
      .min(8, '８文字以上のパスワードを入力してください。')
      .required('入力必須です。')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!-@#$%^&*?]{8,50}$/,
        '半角の英大文字、英小文字、数字の組み合わせが必要です。',
      ),
    confirm_password: Yup.string().oneOf([Yup.ref('password')], 'パスワードが一致していません。'),
  })

  return (
    <div className="app p-0">
      <div  className={loading ? 'background-drop' : ''} id="outer-container">
        <Header showMenu={showMenu} setShowMenu={setShowMenu} sendEmailToUser={sendEmailToUser} />
        <DialogInfoSendMail
          show={showMessageSendMailSuccess}
          onHide={() => setShowMessageSendMailSuccess(false)}
          email={localStorage.getItem('email') ?? ''}
        />
        <main id="page-wrap">
          <div className="header bg-blue-300 text-center text-2xl text-white position-relative">
            <div className="position-absolute">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => setShowMenu(true)}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <span>Happiness Book Web</span>
            <div className="position-absolute button-back-home">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => history.push('/home')}>
                トップへ
              </button>
            </div>
          </div>
          <div id="wrapper">
            <h1 className="infomation text-center">{CHANGE_PASSWORD.TITLE}</h1>
            <h5>{CHANGE_PASSWORD.DESCRIPTION1}</h5>
            <h5>{CHANGE_PASSWORD.DESCRIPTION2}</h5>
            <h5>{CHANGE_PASSWORD.DESCRIPTION3}</h5>
            <Formik
              enableReinitialize
              initialValues={{
                old_password: '',
                password: '',
                passwordConfirm: '',
              }}
              onSubmit={handleSubmitChangePassword}
              validationSchema={validationSchemaChangePasswordForm}
            >
              {({ errors }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="old_password">{CHANGE_PASSWORD.CURRENT_PASSWORD}</label>
                    <Field
                      type={isShowPassword ? 'text' : 'password'}
                      className="form-control"
                      id="old_password"
                      name="old_password"
                      aria-describedby="oldpasswordHelp"
                      placeholder={CHANGE_PASSWORD.CURRENT_PASSWORD}
                    />
                    <p className="error text-red"><ErrorMessage name="old_password" /></p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">{CHANGE_PASSWORD.NEW_PASSWORD}</label>
                    <Field
                      type={isShowPassword ? 'text' : 'password'}
                      className="form-control"
                      id="password"
                      name="password"
                      aria-describedby="passwordHelp"
                      placeholder={CHANGE_PASSWORD.NEW_PASSWORD}
                    />
                    <p className="error text-red"><ErrorMessage name="password" /></p>
                  </div>
                  <div className="form-group">
                    <label htmlFor="confirm_password">{CHANGE_PASSWORD.NEW_PASSWORD_CONFIRM}</label>
                    <Field
                      type={isShowPassword ? 'text' : 'password'}
                      className="form-control"
                      id="confirm_password"
                      name="confirm_password"
                      aria-describedby="confirmPasswordHelp"
                      placeholder={CHANGE_PASSWORD.NEW_PASSWORD_CONFIRM}
                    />
                    <p className="error text-red"><ErrorMessage name="confirm_password" /></p>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex justify-end">
                      <div className="container-button">
                        <input type="checkbox" className="show-password" checked={isShowPassword} onChange={() => setShowPassword(!isShowPassword)} />
                        <label className="title-option" htmlFor="checkbox-agree">{LOGIN.SHOW_PASSWORD}</label>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className={Object.keys(errors).length > 0 ? 
                          "w-40 px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                          : "w-40 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                        disabled={Object.keys(errors).length > 0}
                      >
                        {FORGOT_PASSWORD.BTN_SUBMIT_2}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </main>
      </div>
    </div>
  )
}
export default ChangePasswordPage
