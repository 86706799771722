import { createStore } from 'redux'
import { createBrowserHistory } from 'history'
import rootReducer from './root-reducer'

export const history = createBrowserHistory()

function configureStore(preloadedState?: any) {
  // Create middleware
  // const sagaMiddleware = createSagaMiddleware()

  // Create Store
  const store = createStore(
    rootReducer(history),
    preloadedState

    // ,applyMiddleware(sagaMiddleware, routerMiddleware(history))
  ) as any
  // Run middleware
  // store.sagaTask = sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
