import { useState } from 'react'
import { sendEmailToUserApi } from '../Survey/listQuestion-api'
import { useHistory } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css';
import '../Survey/survey.scss'
import { useToasts } from 'react-toast-notifications';
import DialogInfoSendMail from '../Infomation/dialogInfoSendMail';
import Header from './Header';

const stateScreen = [
  {
    section: '健康とは',
    sectionId: 0,
    text: 'WHO（世界保健機関）によって「健康とは、病気でないとか、弱っていないということではなく、肉体的にも、精神的にも、そして社会的にも、すべてが満たされた状態にあることをいいます。」（日本WHO協会訳）と定義されています。社会的とは人や社会とのつながりにおいてという意味です。マイナスが無い状態ではないとわざわざ強調して言っている部分がとても重要です。この定義で捉えると、旅行や飲み会など、様々な行動がココロ・カラダ・キズナ（人や社会とのつながり）の健康行動と言えます。私たちは新しく始める健康行動ではなく、今行っている健康行動のバランスを整えていく意識に切り替えていく事を推奨しています。',
  },
  {
    section: '未病（みびょう）とは',
    sectionId: 1,
    text: '未病とは健康ではない、かといって病気でもない状態をいいます。\n今までは病気か否かという２つに分けた考え方で、社会の仕組みが作られてきました。医師は病気か否かをを診断するのが大きな仕事の一つです。近年計測機器が大きく発達し、また生活習慣病といわれているような徐々に進行する病気もはっきりとしてきていることから、未病の状態で健康に戻すための取り組みが大変注目されています。\n2019年6月には富山大学 、東京大学の研究グループは、数学理論（動的ネットワークバイオマーカー理論）により、未病状態を生体信号の「ゆらぎ」として科学的に検出することに成功しています。',
  },
  {
    section: 'Well-being（ｳｪﾙﾋﾞｰｲﾝｸﾞ）とは',
    sectionId: 2,
    text: 'WHO（世界保健機関）の定義する健康とはココロもカラダもキズナ（人や社会とのつながり）もすべてが満たされた状態をいいます。そしてその状態のことをWell-being（ウェルビーイング）といいます。（日本語では幸福と訳されます。）\nこのWell-being、言葉は一つですが、どんな状態が満ち足りた状態なのか、その形は人によって大きく違います。例えば、飲み会に行ってワイワイやることで、ココロやキズナの健康が満たされる人もいれば、逆にストレスがたまる人もいるのが実情です。\n自分にとってのWell-being（な状態）は、自分自身が明確にしてく必要があります。HappinessBookは画一的な正解のある健康ではなく、一人ひとりに個別最適化した健康を得てもらうことを目的とし、設計されています。',
  },
  {
    section: '健幸度®︎とは',
    sectionId: 3,
    text: 'Happiness BookではWell-beingに関わる要素を極力網羅して、「健幸度®︎」として表現しています。\n具体的にはココロ、カラダ、キズナの健康に関する定性・定量データのほか※、一人ひとリの外部環境であるライフステージと内部環境であるパーソナリティ（別項で説明）を計算し、一人ひとりの補正をかけたうえで「健幸度®︎」を算出しています。\n意味としては完全な状態に対しての満たされ度を示しますので、％で示しています。\n※定量データについては一部システム連携によって統合されます。',
  },
  {
    section: '自己評価とは',
    sectionId: 4,
    text: '自分自身を自分自身が評価することですが、主観ですのでばらつきがあります。しかし自分自身で感じている実感そのものもココロの健康に影響しているので、測定する事は重要です。繰り返し記録することで自分自身の主観の推移が見える化されてきます。\n継続して記録することで自分自身を客観的に知る良い指標になります。',
  },
  {
    section: 'ココロの健康とは',
    sectionId: 5,
    text: '心には自分自身のあり様においての満たされた状態と、他者とのかかわりあいにおいて満たされた状態がありますが、 HappinessBookでは前者を「ココロの健康」、後者を「キズナの健康」として切り分けています。\nココロの健康が何によって満たされるのかにおいては人によって大きく違うため、形としての一般的な正解はありません。共通で言えることは自分自身の心と向き合うことで、心を感じる力を育てることが必要という事です。',
  },
  {
    section: 'カラダの健康とは',
    sectionId: 6,
    text: 'HappinessBookでは肉体的な健康を「カラダの健康」と言っています。人間の身体には３７兆もの細胞があると言われていますが、いまこの文章を読んでいる瞬間にも常に細胞は生まれ変わっています。そうした中でも原型をとどめているのは、バランスを取ろうとする「恒常性（ホメオスタシス）」という仕組みがあるからです。\nカラダの健康は、ココロの健康・キズナの健康とも密接に影響しあっていますし、腸内細菌などとも相互に影響しあっています。この様に、カラダの健康はとても絶妙なバランスの上に成り立っているのです。',
  },
  {
    section: 'キズナの健康とは',
    sectionId: 7,
    text: '心には自分自身のあり様においての満たされた状態と、他者とのかかわりあいにおいて満たされた状態がありますが、 HappinessBookでは前者を「ココロの健康」、後者を「キズナの健康」として切り分けています。\n普段感じなくても、人はキズナの健康を必要としていますが、満たされた状態がどのような状態か、は人によって、またライフステージによっても大きく違うため、形としての一般的な正解はありません。\n共通で言えることは、自分自身の心を感じる力を育てる事で、自分の心の中にある他者の存在（共感力）を育てることが必要という事です。',
  },
  {
    section: 'バランスとは',
    sectionId: 8,
    text: 'カラダとココロとキズナはそれぞれがお互いに影響しあっています。例えば、病気（カラダの健康）をすれば、気分（ココロの健康）や、友人との付き合い（キズナの健康）にも影響します。この３つの健康はお互いに影響しあっているので、健幸度®︎を改善するには、各要素のバランスを意識しながら改善を図っていく必要があります。実はこれがストレスをためずに改善する事にもつながるのです。\nHappiness Book はココロ、カラダ、キズナの健康変化を記録する事のできるアプリです。Well-beingな状態を得るために、継続して記録することでバランスを見える化し、我慢せずに少しづつ生活習慣を変えていきましょう！',
  },
  {
    section: 'バランス度とは',
    sectionId: 9,
    text: 'SHD研究所の研究によれば、Well-beingはココロ、カラダ、キズナの健康バランスと関係があることがわかっています。Well-beingが低い間はまずはカラダの健康。Well-beingが高くなればなるほど、そのバランスが重要になります。\n何か一つの健康だけに取り組んでも、バランスが崩れ、かえってWell-beingが下がるのです。例えば太ってきたからといってダイエットに励んでも、ストレスが多くココロが満たされていないと、結局Well-beingが下がり、ダイエットは失敗。逆にリバウンドしてしまいます。\n我慢せずに少しづつ生活習慣を変えていく事が大事です。',
  },
  {
    section: '未病状態の見方とは',
    sectionId: 10,
    text: 'ココロ・カラダ・キズナの健康状態は常に変化していますので、ある時点での状態だけで判断はできません。「状態」だけではなく「変化」に注目する事が大切です。\n継続して自分の状態を記録していくことで、良くなっているのか否かの「傾向」がつかめます。\n主観だけでは気づけないため、Happiness Book で記録をし、自分自身を多角的にい分析していきましょう。',
  },
  {
    section: '認識ギャップとは',
    sectionId: 11,
    text: '認識とはある物事を知り、その本質・意義などを理解することです。\nHappinessBookでは自分自身で認識したものと、自分以外の客観的な認識との間の差を認識ギャップといっています。\n認識ギャップが起きる理由はいくつもありますが、知識の有無やパーソナリティ（別項にて説明）も大きな理由です。\n例えばおなかが痛かった時、知識の有無によって、状況に対する認識は違ったものになります。\nまた、みんなでワイワイガヤガヤする場面で、パーソナリティの違いによって、楽しいと感じるか、つまらないと感じるかは人それぞれです。',
  },
  {
    section: '数値について',
    sectionId: 12,
    text: '健幸度®︎はフルな状態に対しての満たされ度なので「%」で表示しています。０～１００％の範囲があり、１００％が最も良い状態を表します。\nココロの健康、カラダの健康、キズナの健康に関わる数値は評価なので「点」で表示しています。０～１００点の範囲があり、１００点が最も良い状態を表します。',
  },
  {
    section: 'パーソナリティとは',
    sectionId: 13,
    text: '人格・個性・性格と同義で、ある物事に対して特徴的な感情や意志などの特性をいいます。他者との相対で比較するものであり、これ自体に「良い」「悪い」という考え方はありません。自分の個性として受け入れることで、変える事の出来ない外部環境に対して一喜一憂する生き方から、自分をうまく乗りこなす方に切り替えることができます。',
  },
  {
    section: '【感じ方特性】',
    sectionId: 14,
    text: '直観的に感じる特性を表します。認知よりも早いため無意識レベルにおいてもその特性は現れます。敏感性、自己肯定感、協調性、焦点、楽観性で測定しています。',
  },
  {
    section: '【敏感性】',
    sectionId: 15,
    text: '敏感性はマイナス感情につながる刺激にどれくらい敏感かを測っています。この特性が高い人は、問題点を抽出したり、問題が発生する前に事前に準備しておく能力が高いとも言われています。逆に低い人は、物事に動じず、冷静に判断を下すことができる一方で、自分の周りにある危険に気づくのが遅いという面もあります。',
  },
  {
    section: '【自己肯定感】',
    sectionId: 16,
    text: 'そのままの自分を認め、 「自分は大切な存在だ」「自分はかけがえのない存在だ」と思えるかを測っています。人と比較して自分を評価するのではない点が重要です。この感覚を持てると、自分だけでなく他者も尊重できるようになり、お互いに尊重し合える関係が作れます。自己肯定力が高いと外からの評価で揺らぐことなく、感情が安定するため、物事を肯定的に捉え、何事にも意欲的がわきやすくなります。',
  },
  {
    section: '【協調性】',
    sectionId: 17,
    text: '協調性が高いと、他者への共感能力が高いことになります。面倒見のいい人物として周囲からの信頼も得やすいでしょう。しかし時として、他人を大事にするあまり、自己犠牲を強いてしまう傾向もあるため、注意が必要です。\n一方、協調性が低い人は、自分なりの理論や考え方で物事を判断し、個人的な思いや情に流されることがあまりないため、問題解決能力やシステム構築に強みがでます。',
  },
  {
    section: '【焦点】',
    sectionId: 18,
    text: '直観的に自分にとってプラスに働く利得に注目する（促進焦点）のか、自分にとってのマイナスを防ぐ方に注目する（予防焦点）のかを測っています。モチベーションの源泉ともなりますので、目標を定めるときには自分にあった表現の方がしっくりくるでしょう。',
  },
  {
    section: '【楽観性】',
    sectionId: 19,
    text: '事実は変わらないのですが、自分の感情に対してプラスの捉え方として認識するのか、マイナスの捉え方として認識するのかを示します。特に不確実性の高い情報に対してその差がはっきりと出ます。',
  },
  {
    section: '【認知特性】',
    sectionId: 20,
    text: '無意識に近い状態で直観的に感じた後、我々はその物事を意識上で認知します。認知とはある事柄をはっきりをはっきりと認めることですが、同じものを見ても、人によって特性がでます。その際に現れるのが認知特性です。それぞれの特性はだれしも持っていて、その特性の割合によって違いが出ると考えます。\nHappinessBookでは認知特性を具体と抽象、直観と論理で測定していますが、思考型特性、詳細型特性、社交型特性、オプション型特性として特徴をわかりやすくしています。',
  },
  {
    section: '【思考型特性】',
    sectionId: 21,
    text: '論理的で抽象的に物事をとらえる特性を表しています。この特性が強い人にとって、知りたいのは「目的」や「裏付け」です。中長期的な視点においての目的を明確にし、客観的なデータに基づく明確な根拠を求める傾向があります。',
  },
  {
    section: '【詳細型特性】',
    sectionId: 22,

    text: '論理的で具体的に物事をとらえる傾向があります。知りたいのは「行動計画」「信頼性」「安全性」です。具体的安全性や具体的な手順を求める傾向があります。',
  },
  {
    section: '【社交型特性】',
    sectionId: 23,
    text: '直観的で具体的に物事をとらえる傾向があります。社交型特性が強い人は、他の人がどう思っているのか、自分がその相手を好きか嫌いかという直観を重視します。必ずしも人が好きという事ではなく、「誰が」という点について興味関心が向くことが多くなります。',
  },
  {
    section: '【オプション型特性】',
    sectionId: 24,
    text: '直観的で抽象的に物事をとらえる傾向を示します。知りたいのはどれだけ「新しい」か。長期的な視点において、面白い、楽しいと思えるビジュアルを重視する傾向があります。チャレンジを求める傾向があります。',
  },
  {
    section: '【意思決定特性】',
    sectionId: 25,
    text: '意思を決定する際に現れる特性です。決定基準、自己一致、開放性で測定しています。',
  },
  {
    section: '【決定基準】',
    sectionId: 26,
    text: '何かを決めたり行動を選択する時に、その基準が自分の外にあるか、自分の中にあるかを測っています。自分の外にある「外的基準型」の場合、人におすすめされたり、と自分の中にある「内的基準型」の２つのパターンがあります。外的基準の高い人は人や権威といったもので意思決定をしやすくなる傾向があり、内的基準の高い人は自分の納得性を重視して意思決定する傾向があります。',
  },
  {
    section: '【自己一致】',
    sectionId: 27,
    text: '「自己一致」は自己コントロール能力の高さを測っています。自己一致が高い人は、自制心が働きやすいためストレス耐性が強く、衝動的な行動を抑えて理性的な行動をとります。時として柔軟性に欠け、アドリブも苦手なので、臨機応変な対応が求められる状況は不得意です。\n一方自己一致が低い人は注意散漫で飽きっぽい性格とも言えますが、さまざまなものに興味を持ち、行動力を発揮する能力を兼ね備えているとも言えます。一貫性を気にせず、物事に執着せず自分を切り替えられる面もあります。',
  },
  {
    section: '【開放性】',
    sectionId: 28,
    text: '新しい知識や体験に対する積極性を測定しています。高くなればなるほど、感性を刺激する創造的、抽象的、芸術的なものへの関心が強くなり、低いほど、安定志向で保守的な性質が表れやすくなります。',
  },
  {
    section: '【行動特性】',
    sectionId: 29,
    text: '意思決定したものを外部に表出する度合いを表します。思ったこと考えたことを外部表出する程度を示す外部表出性、自らの発信で外部に影響を与える程度を示す自己主張性、逆に外部の発信が自分に影響を与える程度を示す柔軟性があります。この分析では柔軟性は意思決定特性としてとらえ、行動特性としては、外部表出性と自己主張性を合わせたものとして外向性を採用しています。',
  },
  {
    section: '【外向性】',
    sectionId: 30,
    text: '「外向性」は社交性や活動性、積極性を表します。外向性が高いと、活動的なタイプと言えます。逆に外向性が低い人はおとなしく、人見知りのケースもあります。',
  },
];

const FAQ = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showMessageSendMailSuccess, setShowMessageSendMailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listOpen, setListOpen] = useState<number[]>([])

  const sendEmailToUser = async () => {
    try {
      setLoading(true)
      const checkUserAnswer = localStorage.getItem('isAnswer');
      if (!checkUserAnswer || checkUserAnswer === 'false') {
        console.log(checkUserAnswer)
        addToast('アンケートを回答しないとWell-Being評価できません。アンケートの回答を開始してください。', { appearance: 'error' });
        return;
      }
      const checkEmailExist = localStorage.getItem('email');
      if (!checkEmailExist) {
        addToast('メールアドレスが保存されていません。', { appearance: 'error' });
        return;
      }
      await sendEmailToUserApi({email: [checkEmailExist]});
      setLoading(false);
      setShowMessageSendMailSuccess(true);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  return (
    <div className="app p-0">
      <div className={loading ? 'background-drop' : ''} id="outer-container">
        <Header showMenu={showMenu} setShowMenu={setShowMenu} sendEmailToUser={sendEmailToUser} />
        <DialogInfoSendMail
          show={showMessageSendMailSuccess}
          onHide={() => setShowMessageSendMailSuccess(false)}
          email={localStorage.getItem('email') ?? ''}
        />
        <main id="page-wrap">  
          <div className="header bg-blue-300 text-center text-2xl text-white position-relative">
            <div className="position-absolute">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => setShowMenu(true)}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <span>Happiness Book Web</span>
            <div className="position-absolute button-back-home">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => history.push('/home')}>
                トップへ
              </button>
            </div>
          </div>
          <div className="content-list">
            <div className="sm:col-span-12  md:col-span-12 lg:col-span-6 xl:col-span-6 ">
              <div className="card">
                  <div className="card-header">
                    <h5 className="card-title">用語説明</h5>
                  </div>
                  <div className="card-body">
                    {stateScreen.map((item, index) => (
                      <div id="accordion-flush" data-accordion="collapse" data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white" data-inactive-classes="text-gray-500 dark:text-gray-400">
                        <h5 id="accordion-flush-heading-1">
                          <button type="button" onClick={() => listOpen.includes(item.sectionId) ? setListOpen(listOpen.filter(itemOpen => itemOpen !== item.sectionId)) : setListOpen([item.sectionId].concat(listOpen))} className="flex justify-between items-center p-1 w-full font-medium text-left text-gray-500 border-b border-dashed border-gray-200 dark:border-gray-700 dark:text-gray-400" data-accordion-target={`#accordion-flush-body-${index}`} aria-expanded="true" aria-controls={`accordion-flush-body-${index}`}>
                            <span>{item.section}</span>
                            <span className={`${!listOpen.includes(item.sectionId) ? '' : 'hidden'}`}><i className="fas fa-angle-down" data-accordion-icon></i></span>
                            <span className={`${listOpen.includes(item.sectionId) ? '' : 'hidden'}`}><i className="fas fa-angle-up" data-accordion-icon></i></span>
                          </button>
                        </h5>
                        <div id={`accordion-flush-body-${index}`} className={`${listOpen.includes(item.sectionId) ? '' : 'hidden'}`} aria-labelledby={`accordion-flush-heading-${index}`}>
                          <div className="p-1 border-b border-dashed border-gray-200 dark:border-gray-700">
                            <p className="mb-2 text-gray-500 dark:text-gray-400">{item.text}</p>
                          </div>
                        </div>
                      </div>
                    ))}  
                  </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
export default FAQ;
