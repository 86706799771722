import { useState } from 'react'
import { requestForgotPassword, requestPassword } from '../Survey/listQuestion-api'
import { Link, useHistory } from 'react-router-dom'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useToasts } from 'react-toast-notifications'
import * as Yup from 'yup';
import { FORGOT_PASSWORD, LOGIN } from '../../lang/Survey.lang'
import '../Survey/survey.scss'

const ForgotPasswordPage = () => {
  const { addToast } = useToasts()
  const history = useHistory();
  const [stepDisplay, setStepDisplay] = useState(1);
  const [formData, setFormData] = useState({
    userId: '',
    email: '',
    code: '',
    password: '',
    passwordConfirm: '',
  });
  const [isShowPassword, setShowPassword] = useState(false);

  const [isShowMessageError, setShowMessageError] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      const dataForgotPassword = {
        email: values.email,
      }
  
      const res = await requestForgotPassword(dataForgotPassword);
      if (res?.data?.id) {
        setStepDisplay(2);
        setFormData({ ...formData, userId: res.data.id });
      } else {
        setShowMessageError(true);
      }
    } catch (error: any) {
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
    
  }

  const handleSubmitChangePassword = async (values: any) => {
    try {
      const dataResetPassword = {
        user: formData.userId,
        otp_code: values.code,
        new_password: values.password,
      }
  
      const res = await requestPassword(dataResetPassword);
      if (res?.error_code === 0) {
        history.push('/survey-login');
        addToast("パスワードを設定しました。", { appearance: 'success' })
      } 
    } catch (error: any) {
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }
  
  const validationSchemaForm = Yup.object({
    email: Yup.string()
    .max(254, 'メールアドレスは長いすぎて、登録できません。')
    .required('入力必須です。')
    .matches(
      /^[^\uFF10-\uFF19\uFF21-\uFF3A\uFF41-\uFF5A]+$/,
      'メールアドレスに不正な文字が含まれています。',
    )
    .email('正しいメールアドレスを入力してください。'),
  })

  const validationSchemaChangePasswordForm = Yup.object({
    password: Yup.string()
      .min(8, '８文字以上のパスワードを入力してください。')
      .required('入力必須です。')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!-@#$%^&*?]{8,50}$/,
        '半角の英大文字、英小文字、数字の組み合わせが必要です。',
      ),
    confirm_password: Yup.string().oneOf([Yup.ref('password')], 'パスワードが一致していません。'),
    code: Yup.string().required('入力必須です。'),
  })

  return (
    <div className="app">
      {stepDisplay === 1 && (
        <div id="wrapper">
          <h1 className="infomation text-center">{FORGOT_PASSWORD.TITLE}</h1>
          <h5>{FORGOT_PASSWORD.TEXT1}</h5>
          <h5>{FORGOT_PASSWORD.TEXT2}</h5>
          <Formik
            enableReinitialize
            initialValues={formData}
            onSubmit={handleSubmit}
            validationSchema={validationSchemaForm}
          >
            {({ errors }) => (
              <Form>
                <div className="form-group">
                  <label>{LOGIN.EMAIL}</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="email"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder={LOGIN.EMAIL_PLACEHOLDER}
                  />
                  <p className="error text-red"><ErrorMessage name="email" /></p>
                </div>
                {isShowMessageError && (<p className="error text-red">メールアドレスが正しくありません</p>)}
                <div className="flex flex-col">
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 
                        "w-40 px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                        : "w-40 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {FORGOT_PASSWORD.BTN_SUBMIT}
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="m-0"><Link className="text-primary font-bold" to="/survey-login">{LOGIN.LOGIN_LINK}</Link></p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {stepDisplay === 2 && (
        <div id="wrapper">
          <h1 className="infomation text-center">{FORGOT_PASSWORD.TITLE}</h1>
          <h5>{FORGOT_PASSWORD.DESCRIPTION}</h5>
          <h5>{FORGOT_PASSWORD.NOTICE}</h5>
          <Formik
            enableReinitialize
            initialValues={formData}
            onSubmit={handleSubmitChangePassword}
            validationSchema={validationSchemaChangePasswordForm}
          >
            {({ errors }) => (
              <Form>
                <div className="form-group">
                  <label htmlFor="code">{LOGIN.CODE}</label>
                  <Field
                    type="text"
                    className="form-control"
                    id="code"
                    name="code"
                    aria-describedby="codeHelp"
                    placeholder={LOGIN.CODE_CONFIRM}
                  />
                  <p className="error text-red"><ErrorMessage name="code" /></p>
                </div>
                <div className="form-group">
                  <label htmlFor="password">{LOGIN.PASSWORD}</label>
                  <Field
                    type={isShowPassword ? 'text' : 'password'}
                    className="form-control"
                    id="password"
                    name="password"
                    aria-describedby="passwordHelp"
                    placeholder={LOGIN.PASSWORD_PLACEHOLDER}
                  />
                  <p className="error text-red"><ErrorMessage name="password" /></p>
                </div>
                <div className="form-group">
                  <label htmlFor="confirm_password">{LOGIN.RE_PASSWORD}</label>
                  <Field
                    type={isShowPassword ? 'text' : 'password'}
                    className="form-control"
                    id="confirm_password"
                    name="confirm_password"
                    aria-describedby="confirmPasswordHelp"
                    placeholder={LOGIN.RE_PASSWORD_PLACEHOLDER}
                  />
                  <p className="error text-red"><ErrorMessage name="confirm_password" /></p>
                </div>
                <div className="flex flex-col">
                  <div className="flex justify-end">
                    <div className="container-button">
                      <input type="checkbox" className="show-password" checked={isShowPassword} onChange={() => setShowPassword(!isShowPassword)} />
                      <label className="title-option" htmlFor="checkbox-agree">{LOGIN.SHOW_PASSWORD}</label>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <button
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 
                        "w-40 px-3 py-2 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" 
                        : "w-40 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700"}
                      disabled={Object.keys(errors).length > 0}
                    >
                      {FORGOT_PASSWORD.BTN_SUBMIT_1}
                    </button>
                  </div>
                  <div className="text-center">
                    <p className="m-0"><Link className="text-primary font-bold" to="/survey-login">{LOGIN.LOGIN_LINK}</Link></p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  )
}
export default ForgotPasswordPage
