/* eslint-disable import/prefer-default-export */
export const WELCOME = {
  INTERESTED: 'あなたの自分自身の健幸度を測定しませんか。',
  DESCRIPTION: "開始ボタンで測定をスタートしてみてください。",
  START: '　開始　',
  EVENT_CODE_TITLE: '組織コードをお持ちの方のみ入力してください。先頭のBAは入力せずBAの後の6桁の数字のみご入力ください。',
  EVENT_CODE: '組織コード',
  SUBMIT: '次へ',
}
export const INFO = {
  INFOMATION: '基本情報を入力してください',
  NAME: '氏名',
  ENTER_NAME: '名前を入力してください',
  DOB: '生年月',
  GENDER: '性別',
  MALE: '男性',
  FEMALE: '女性',
  OTHER: '回答しない',
  EMAIL: 'メールアドレス',
  ENTER_EMAIL: '受信可能なメールアドレスを入力してください',
  COMPANY: '組織コード',
  ENTER_COMPANY: '組織コードをお持ちの方のみ入力してください。先頭のBAは入力せずBAの後の6桁の数字のみご入力ください。',
  SUBMIT: '次へ',
}
export const VALIDATION = {
  NOT_EMPTY: '入力必須項目です。',
  MUST_NOT_BE_LESS_THAN: 'must not be less than',
  MUST_NOT_BE_LARGER: 'must not be larger',
  MISMATCHED: '一致する情報がありません。',
  MAILFORMED: '無効なメールアドレスです。',
  ILLEGAL: '無効なデータです。',
  CHARACTER: 'characters',
}
export const SURVEY = {
  PLACEHODER: ' ',
  SUBMIT: '次へ',
  COMPLETED: '完了',
  PREVIEW_PDF: '評価結果のプレビュー',
  COMPLETE_MESSAGE: 'アンケート回答が完了しました。お疲れ様でした。',
  COMPLETE_MESSAGE_SURVEY_1: 'アンケート回答が完了しました。お疲れ様でした。',
  COMPLETE_MESSAGE_SURVEY_2: 'ブラウザまたはタブを閉じて下さい。',
  DOWNLOAD: '評価結果のダウンロード',
  BTN_TERM_SUBMIT: '次へ',
  TERM_CONDITION: '利用規約に同意する',
  AGREE: '個人情報の取り扱いに同意する',
  ERROR_1: 'アンケート番号',
  ERROR_1_2: '組織コード',
  ERROR_2: 'は見つかりませんでした。',
  INPUT_AGAIN: ' 正しいアンケート番号を確認して入力してください。',
  INPUT_AGAIN_2: ' 正しい組織コードを確認して入力してください。',
  ERROR_LINE_3: 'ブラウザまたはタブを閉じて下さい。',
  CLOSE: '近い',
  HOME: 'ホーム',
  SUCCESS_SURVEY: 'この画面を閉じる',
  AMOUNT_QUESTION: '問未回答',
}

export const LOGIN = {
  TITLE: 'Happiness Book Web ログイン',
  EMAIL: 'メールアドレス',
  EMAIL_PLACEHOLDER: 'メールアドレスを入力してください',
  PASSWORD: 'パスワード',
  RE_PASSWORD: 'パスワード（再入力）',
  PASSWORD_PLACEHOLDER: 'パスワードを入力してください',
  RE_PASSWORD_PLACEHOLDER: 'パスワード（再入力）を入力してください',
  FORGOT_PASSWORD_LINK: 'パスワードを忘れた？',
  REGISTER_LINK: '新規アカウント登録はこちら',
  SUBMIT: 'ログイン',
  LOGIN_LINK: 'パスワードをお持ちのかたはログインしてください',
  SHOW_PASSWORD: 'パスワードを表示する',
  CODE_CONFIRM: '認証コードを入力してください',
  CODE: '認証コード',
}

export const SIGN_UP = {
  TITLE: '基本情報を入力してください',
}

export const FORGOT_PASSWORD = {
  TITLE: 'パスワード再設定',
  TEXT1: '不正アクセス防止のため、 Happiness Bookに登録されているメールアドレスに認証メールを送信します。',
  TEXT2: '登録済みのメールアドレスを入力してください。',
  RE_PASSWORD: 'パスワード（再入力）',
  INPUT: {
    LABEL: 'メールアドレス',
    TITLE: 'メールアドレスを入力してください。',
  },
  BTN_SUBMIT: '送信',
  HEADER: '認証コードを入力',
  DESCRIPTION:
    'Happiness Bookに登録されているメールアドレスに認証コードを送信しました。',
  NOTICE: 'メールで受け取った認証コードを入力してください。',
  BTN_SUBMIT_1: '認証',
  BTN_SUBMIT_2: '更新',
  BTN_BACK: '閉じる',
}

export const CHANGE_PASSWORD = {
  TITLE: 'パスワードの更新',
  DESCRIPTION1: 'セキュリティのため、パスワードをこまめに変更することをお勧めします。',
  DESCRIPTION2: 'パスワード変更後、一旦ログアウトされますのでご注意ください。',
  DESCRIPTION3: '新パスワードで再度ログインしてサービスを継続ご利用ください。',
  CURRENT_PASSWORD: '現在のパスワード',
  NEW_PASSWORD: '新しいパスワード',
  NEW_PASSWORD_CONFIRM: '新しいパスワード（再入力）'
}
