import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import CustomRoute from '../router/CustomRoute'
import { useQuery } from '../containers/Welcome/index';
import LoginPage from '../containers/Login'
import HomePage from '../containers/Home';
import SignUpPage from '../containers/Signup';
import ForgotPasswordPage from '../containers/ForgotPassword';
import TermAndService from '../containers/Home/TermAndService';
import FAQ from '../containers/Home/FAQ';
import Account from '../containers/Home/Account';
import ChangePasswordPage from '../containers/Home/ChangePassword';

const Survey = React.lazy(() => import('../containers/Survey/Survey'))
const SurveyType = React.lazy(() => import('../containers/Survey/SurveyType'))

export default function BaseLayout() {
  const query = useQuery();
  const sid = query.get('sid');
  return (
    <div className="flex">
      <div className="w-full">
        <Switch>
          {<Redirect exact from="/" to="/survey" />}
          <CustomRoute path="/survey" component={sid ? SurveyType : Survey} />
          <CustomRoute path="/survey-login" component={LoginPage} />
          <CustomRoute path="/signup" component={SignUpPage} />
          <CustomRoute path="/home" component={HomePage} />
          <CustomRoute path="/term-and-service" component={TermAndService} />
          <CustomRoute path="/faq" component={FAQ} />
          <CustomRoute path="/account" component={Account} />
          <CustomRoute path="/forgot-password" component={ForgotPasswordPage} />
          <CustomRoute path="/change-password" component={ChangePasswordPage} />
          {/* <CustomRoute path="/survey-type" component={} /> */}
          <Redirect to="error" />
        </Switch>
      </div>
    </div>
  )
}
