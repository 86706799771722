import { useEffect, useState, useCallback } from 'react'
import { deleteUser, getProfile, sendEmailToUserApi, updateProfile } from '../Survey/listQuestion-api'
import { useHistory, Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'react-circular-progressbar/dist/styles.css';
import '../Survey/survey.scss'
import { useToasts } from 'react-toast-notifications';
import DialogInfoSendMail from '../Infomation/dialogInfoSendMail';
import Header from './Header';
import DialogDeleteUser from './DialogDeleteUser';
import { FORGOT_PASSWORD } from '../../lang/Survey.lang';

const validationSchemaForm = Yup.object({
  username: Yup.string()
    .min(1, '氏名を入力してください。')
    .max(255, '255桁〜10桁の数字を入力してください。')
    .required('入力必須です。'),
  nickname: Yup.string()
    .min(1, '氏名を入力してください。')
    .max(255, '255桁〜10桁の数字を入力してください。')
    .required('入力必須です。')
    .nullable(),
})

const Account = () => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [showMenu, setShowMenu] = useState(false);
  const [showMessageSendMailSuccess, setShowMessageSendMailSuccess] = useState(false);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: localStorage.getItem('username') ?? '',
    nickname: localStorage.getItem('nickname') ?? '',
    email: localStorage.getItem('email') ?? '',
    bio: '',
    birthday: '',
  });
  const [isModeEdit, setModeEdit] = useState(false);

  const getUserInfo = useCallback(async () => {
    try {
      const userInfo = await getProfile();
      if (userInfo?.data?.id) {
        setUserInfo({
          username: userInfo.data.username,
          nickname: userInfo.data.nickname,
          email: userInfo.data.email,
          bio: userInfo.data.bio ?? '',
          birthday: userInfo.data.birthday,
        })
      }
    } catch (error: any) {
      console.log('error: ', JSON.stringify(error))
      history.push('/survey-login');
    }
  }, [history, setUserInfo])

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      getUserInfo();
    } else {
      history.push('/survey-login');
    }
  }, [getUserInfo, history]);

  const sendEmailToUser = async () => {
    try {
      setLoading(true)
      const checkUserAnswer = localStorage.getItem('isAnswer');
      if (!checkUserAnswer || checkUserAnswer === 'false') {
        console.log(checkUserAnswer)
        addToast('アンケートを回答しないとWell-Being評価できません。アンケートの回答を開始してください。', { appearance: 'error' });
        return;
      }
      const checkEmailExist = localStorage.getItem('email');
      if (!checkEmailExist) {
        addToast('メールアドレスが保存されていません。', { appearance: 'error' });
        return;
      }
      await sendEmailToUserApi({email: [checkEmailExist]});
      setLoading(false);
      setShowMessageSendMailSuccess(true);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true)
      const updateInfo = await updateProfile(values);
      if (updateInfo?.error_code === 0) {
        setModeEdit(false);
        addToast('アカウント情報を更新しました。', { appearance: 'success' })
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  const handleDeleteUser = async () => {
    try {
      setLoading(true)
      const updateInfo = await deleteUser();
      if (updateInfo?.error_code === 0) {
        addToast('アカウントが削除しました。', { appearance: 'success' })
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  return (
    <div className="app p-0">
      <div className={loading ? 'background-drop' : ''} id="outer-container">
        <Header showMenu={showMenu} setShowMenu={setShowMenu} sendEmailToUser={sendEmailToUser} />
        <DialogInfoSendMail
          show={showMessageSendMailSuccess}
          onHide={() => setShowMessageSendMailSuccess(false)}
          email={localStorage.getItem('email') ?? ''}
        />
         <DialogDeleteUser
          show={showModalDeleteUser}
          onHide={() => setShowModalDeleteUser(false)}
          onSubmit={handleDeleteUser}
        />
        <main id="page-wrap">
          <div className="header bg-blue-300 text-center text-2xl text-white position-relative">
            <div className="position-absolute">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => setShowMenu(true)}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <span>Happiness Book Web</span>
            <div className="position-absolute button-back-home">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => history.push('/home')}>
                トップへ
              </button>
            </div>
          </div>
          <div className="content-list">
            <div className="grid md:grid-cols-12 lg:grid-cols-12 xl:grid-cols-12 gap-1">
                <div className="col-span-12 md:col-span-12 lg:col-span-12 xl:col-span-12">
                    <div className="card">
                        <div className="card-header">
                          <h5 className="card-title">アカウント情報</h5>
                        </div>
                        <div className="card-body">
                          <Formik
                            enableReinitialize
                            initialValues={userInfo}
                            onSubmit={(values) => {
                              handleSubmit(values);
                            }}
                            validationSchema={validationSchemaForm}
                          >
                            {({ errors }) => (
                              <Form>
                                <div className="grid md:grid-cols-12 lg:grid-cols-12">
                                  <div className="col-span-12 md:col-span-12 lg:col-span-3 self-center text-right mr-2">
                                    <label htmlFor="username" className="label">氏名</label>
                                  </div>
                                  <div className="col-span-12 md:col-span-12 lg:col-span-9 mb-2">
                                    <Field name="username" type="text" id="username" className="form-control" disabled={!isModeEdit} placeholder="氏名" required />
                                    {errors?.username && (<p className="error text-red"><ErrorMessage name="username" /></p>)}
                                  </div>
                                  <div className="col-span-12 md:col-span-12 lg:col-span-3 self-center text-right mr-2">
                                    <label htmlFor="nickname" className="label">ニックネーム</label>
                                  </div>
                                  <div className="col-span-12 md:col-span-12 lg:col-span-9 mb-2">
                                    <Field name="nickname" type="text" id="nickname" className="form-control" disabled={!isModeEdit} placeholder="ニックネーム" required />
                                    {errors?.nickname && ( <p className="error text-red"><ErrorMessage name="nickname" /></p>)}
                                  </div>
                                  <div className="col-span-12 md:col-span-12 lg:col-span-3 self-center text-right mr-2">
                                    <label htmlFor="username" className="label">メールアドレス</label>
                                  </div>
                                  <div className="col-span-12 md:col-span-12 lg:col-span-9 mb-2">
                                    <Field name="email" type="text" id="email" className="form-control" disabled />
                                  </div>
                                  {isModeEdit && (
                                    <div className=" col-start-4 col-end-13 mt-2 text-center">
                                      <button type="submit" className="btn btn-primary" disabled={Object.keys(errors).length > 0}>決定</button>
                                      <button type="button" onClick={() => setModeEdit(false)} className="btn btn-dark ml-3">キャンセル</button>
                                    </div>
                                  )}
                                </div>
                              </Form>
                            )}
                          </Formik>
                          {!isModeEdit && (
                            <div className="col-start-4 col-end-13 flex justify-center mt-2 text-center">
                              <button type="button" onClick={() => setModeEdit(true)} className="w-40 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-2 mr-2 mb-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-700">変更</button>
                            </div>
                          )}
                          <div className="text-right">
                            <p className="m-0 pb-2"><Link className="text-primary font-bold" to="/change-password">パスワードを更新する</Link></p>
                            <p className="m-0"><a rel="noopener noreferrer" className="text-danger font-bold" href={`https://hbp-basic.happiness-book.jp/account?del`} target="_blank">アカウントを削除する</a></p>
                          </div>
                        </div>
                    </div>
                </div>                         
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
export default Account;
