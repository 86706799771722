import axios, { AxiosRequestConfig } from 'axios'

// export const baseURL = 'http://27.72.29.21:8311/'
// export const baseURL = 'https://backend-api.happiness-book.jp/'
export const baseURL = 'https://backend-basic.happiness-book.jp/'
const apiClient = axios.create({
  baseURL,
  withCredentials: true,
  responseType: 'json',
  timeout: 10000,
})
const apiClientDownload = axios.create({
  baseURL,
  withCredentials: true,
})

const defaultHeaders = {
  'Content-Type': 'application/json',
}

const defaultConfig = async (): Promise<AxiosRequestConfig> => {
  // tslint:disable-next-line: no-shadowed-variable
  const defaultConfig: AxiosRequestConfig = {
    headers: { ...defaultHeaders },
  }
  return defaultConfig
}

/**
 * defaultConfigと引数で指定されたconfigをマージする。
 * @param config
 */
const mergeConfig = async (config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const _defaultConfig = await defaultConfig()
  let headers = _defaultConfig.headers
  if (config && config.headers) {
    headers = { ...headers, ...config.headers }
  }
  return { ..._defaultConfig, ...config, headers }
}

const requestErrorHandler = (err?: any, skipAlert: boolean = false): Promise<any> => {
  if (err.response?.status === 403) {
    localStorage.clear();
    window.location.href = window.location.origin + '/survey-login';
  }
  return Promise.reject(err)
}

export async function get(url: string, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .get(`${url}`, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler)
}

export async function post(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .post(`${url}`, data, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler)
}

export async function put(url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
  const _config = await mergeConfig(config)
  return apiClient
    .put(`${url}`, data, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler)
}

export async function deleteRequest(url: string, config?: AxiosRequestConfig): Promise<any> {
  const data = config && config.data ? config.data : ''
  const _config = await mergeConfig({ ...config, data })
  return apiClient
    .delete(`${url}`, _config)
    .then((res) => res.data)
    .catch(requestErrorHandler)
}

/** 画像取得専用のGETリクエスト */
export async function downloadFile(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  const _config = await mergeConfig({
    ...config,
    data,
    responseType: 'blob',
  })
  return apiClientDownload
    .post(`${url}`,data, _config)
    .then((res) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', 'file.zip') // any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
    .catch((err) => requestErrorHandler(err, true))
}
