/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation} from "react-router-dom";
import { useDispatch } from 'react-redux'

import './index.scss'
import './common.scss'
import logo from '../../assets/image/home/logo.png'
import shareButton from '../../assets/image/home/sharebutton.png'
import h2Image from '../../assets/image/home/h2.png'
import h3Image from '../../assets/image/home/h3.png'
import { AuthTypes } from '../../redux/_auth-redux';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Item = (props: any) => {
  const [isShowPopup, setShowPopup] = useState(false);
  const [isShowMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    if (isShowMessage) {
      const timeoutMessage = setTimeout(() => {
        setShowMessage(false)
      }, 2000)
      return () => clearTimeout(timeoutMessage);
    }
  }, [isShowMessage])

  return (
    <>
      <header>
        <div id="headerInner">
          <h1><img src={logo} alt="Happiness book" /></h1>
          <p id="headerShare">
            <a aria-hidden="true" onClick={() => {
              setShowPopup(true);
              dispatch({ type: AuthTypes.SHOW_POPUP_SHARE, sharePopup: true });
              document.body.classList.add('body');
            }}>
              <img src={shareButton} alt="" />
            </a>
          </p>
        </div>
      </header>
      <div id="contents">
        <section>
          <div className="sectionInner">
            <h2><img src={h2Image} alt="健幸度＆性格傾向測定" /></h2>
            <h3><img src={h3Image} alt="Well-being Check" /></h3>
            <figure className="linkBtnWrapper">
              <p className="linkBtn">
                <a aria-hidden="true" onClick={() => props.onClick()}>測定スタート！</a>
              </p>
            </figure>
            <p>一人ひとりカタチの違うWell-beingを測定するためには、一人ひとりの感じ方の違いや、環境の違いを考慮しなくてはいけません。SHDでは92問の設問からココロ・カラダ・キズナの健康度を一人ひとりのパーソナリティとライフステージで補正し、より客観的な指標としての健幸度を測定しています。</p>
          </div>
        </section>
      </div>

      <footer/>
      {isShowPopup && (
        <div id="popup">
          <h3>このサイトをシェアする</h3>
          <ul id="snsShareList">
            <li id="snsShareListLine"><a href="https://social-plugins.line.me/lineit/share?url=https://web.happiness-book.jp/" target="_blank" rel="nofollow noopener noreferrer"><i className="fa-brands fa-line"></i></a></li>
            <li id="snsShareListX"><a href="http://twitter.com/share?url=https://web.happiness-book.jp&text=健幸度＆性格傾向測定" target="_blank" rel="nofollow noopener noreferrer"><i className="fa-brands fa-square-x-twitter"></i></a></li>
            <li id="snsShareListFb"><a href="http://www.facebook.com/share.php?u=web.happiness-book.jp" target="_blank" rel="nofollow noopener noreferrer"><i className="fa-brands fa-square-facebook"></i></a></li>
          </ul>
          <div id="urlCopy">
            <div>
              <p>https://web.happiness-book.jp/</p>
            </div>
            <button id="copy-url" data-url="https://web.happiness-book.jp/" onClick={() => {
              navigator.clipboard.writeText('https://web.happiness-book.jp/');
              setShowMessage(true)
            }}>URLをコピー</button>
          </div>
          <p id="popupClose" onClick={() => {
            setShowPopup(false);
            dispatch({ type: AuthTypes.SHOW_POPUP_SHARE, sharePopup: false });
            document.body.classList.remove('body');
          }}><i className="fa-solid fa-xmark"></i></p>
          <div className={`success-msg ${isShowMessage ? 'fadeIn' : 'fadeOut'}`} style={{ display: `${isShowMessage ? 'block' : 'none'}` }}>クリップボードにコピーしました</div>
        </div>
      )}
    </>
  )
}

export default Item
