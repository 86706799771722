/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce'
export type IAuthStore = {
  stepFormForcePassword: number
  // isFirstTimeLogin: boolean
  companyId: string | number
  stateForceChangePassword: {
    jwtToken: string
    oldPassword: string
    userId: null | number
  },
  sharePopup: boolean,
}

const { Types, Creators } = createActions({
  updateStepForm: ['stepFormForcePassword'],
  // updateIsFirstTimeLogin: ['isFirstTimeLogin'],
  updateCompanyId: ['companyId'],
  updateStateForceChangePassword: ['stateForceChangePassword'],
  showPopupShare: ['sharePopup'],
})

export const AuthTypes = Types
export default Creators

export const INITIAL_STATE: IAuthStore = {
  stepFormForcePassword: 1,
  // isFirstTimeLogin: true,
  companyId: '',
  stateForceChangePassword: {
    jwtToken: '',
    userId: null,
    oldPassword: '',
  },
  sharePopup: false,
}

export const updateStepForm = (state: IAuthStore, { stepFormForcePassword }: IAuthStore) => ({
  ...state,
  stepFormForcePassword,
})

// export const updateIsFirstTimeLogin = (state: IAuthStore, { isFirstTimeLogin }: IAuthStore) => ({
//   ...state,
//   isFirstTimeLogin,
// })
export const updateStateForceChangePassword = (
  state: IAuthStore,
  { stateForceChangePassword }: IAuthStore
) => ({
  ...state,
  stateForceChangePassword,
})

export const updateCompanyId = (state: IAuthStore, { companyId }: IAuthStore) => ({
  ...state,
  companyId,
})

export const sharePopupAction = (state: IAuthStore, { sharePopup }: IAuthStore) => ({
  ...state,
  sharePopup,
})

export const AuthReducer = createReducer(INITIAL_STATE, {
  [AuthTypes.UPDATE_STEP_FORM]: updateStepForm,
  // [AuthTypes.UPDATE_IS_FIRST_TIME_LOGIN]: updateIsFirstTimeLogin,
  [AuthTypes.UPDATE_COMPANY_ID]: updateCompanyId,
  [AuthTypes.UPDATE_STATE_FORCE_CHANGE_PASSWORD]: updateStateForceChangePassword,
  [AuthTypes.SHOW_POPUP_SHARE]: sharePopupAction,
})
