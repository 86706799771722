import { useEffect, useState } from 'react'
import { getListSurvey, sendEmailToUserApi } from '../Survey/listQuestion-api'
import { useHistory } from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { slide as Menu } from 'react-burger-menu'
import { SURVEY } from '../../lang/Survey.lang'
import '../Survey/survey.scss'
import { ISurvey } from '../Survey/questionType';
import { useToasts } from 'react-toast-notifications';
import DialogInfoSendMail from '../Infomation/dialogInfoSendMail';
import Header from './Header';

const HomePage = () => {
  const { addToast } = useToasts();
  const history = useHistory();
  const [listSurvey, setListSurvey] = useState<ISurvey[]>([]);
  const [showMenu, setShowMenu] = useState(false);
  const [showMessageSendMailSuccess, setShowMessageSendMailSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const getListSurveyAPI = async () => {
    try {
      const res = await getListSurvey()
      if (res?.data?.length > 0) {
        setListSurvey(res.data);
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  useEffect(() => {
    const userId = localStorage.getItem('userId')
    if (userId) {
      getListSurveyAPI();
    } else {
      history.push('/survey-login');
    }
  }, [history]);

  const sendEmailToUser = async () => {
    try {
      setLoading(true)
      const checkUserAnswer = localStorage.getItem('isAnswer');
      if (!checkUserAnswer || checkUserAnswer === 'false') {
        console.log(checkUserAnswer)
        addToast('アンケートを回答しないとWell-Being評価できません。アンケートの回答を開始してください。', { appearance: 'error' });
        return;
      }
      const checkEmailExist = localStorage.getItem('email');
      if (!checkEmailExist) {
        addToast('メールアドレスが保存されていません。', { appearance: 'error' });
        return;
      }
      await sendEmailToUserApi({email: [checkEmailExist]});
      setLoading(false);
      setShowMessageSendMailSuccess(true);
    } catch (error: any) {
      setLoading(false);
      addToast(error?.response.data.message.toString(), { appearance: 'error' })
    }
  }

  return (
    <div className="app p-0">
      <div className={loading ? 'background-drop' : ''} id="outer-container">
        <Header showMenu={showMenu} setShowMenu={setShowMenu} sendEmailToUser={sendEmailToUser} />
        <DialogInfoSendMail
          show={showMessageSendMailSuccess}
          onHide={() => setShowMessageSendMailSuccess(false)}
          email={localStorage.getItem('email') ?? ''}
        />
        <main id="page-wrap">  
          <div className="header bg-blue-300 text-center text-2xl text-white position-relative">
            <div className="position-absolute">
              <button type="button" className="btn bg-blue-300 text-white react-burger-menu-btn" onClick={() => setShowMenu(true)}>
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <span>Happiness Book Web</span>
          </div>
          <div className="content-list">
            <div className="mx-auto px-2 min-h-[calc(100vh-138px)] relative mt-2">
              <div className="grid grid-cols-12 gap-6 grid-flow-row auto-rows-fr md:auto-rows-min mb-2">
                {listSurvey?.map((item) => (
                  <div
                    key={item.id}
                    className="sm:col-span-4 h-full md:col-span-4 lg:col-span-2 xl:col-span-2 rounded-lg cursor-pointer"
                    style={{ backgroundColor: `${item.option.backgroundColor}`}}
                    onClick={() => history.push(`/survey?cc=${localStorage.getItem('companyCode') ?? '267767'}&sid=${item.id}&step=3`)}
                    aria-hidden="true"
                  >
                      <div className="card border-0" style={{ backgroundColor: `${item.option.backgroundColor}`}}>
                        <div className="card-body text-center border-b-0">
                          <div className="text-white text-lg font-bold pb-2">{item.name}</div>
                          <div className="flex justify-center circular-progressbar">
                            <CircularProgressbar value={0} text={`${0}%`} />
                          </div>
                          <div className="pt-2 font-bold text-white">{item.quantity_question} {SURVEY.AMOUNT_QUESTION}</div>
                        </div>
                      </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
        
      </div>
    </div>
  )
}
export default HomePage;
