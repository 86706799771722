import { useHistory } from 'react-router-dom'
import 'react-circular-progressbar/dist/styles.css';
import { slide as Menu } from 'react-burger-menu'
import { getProfile } from '../Survey/listQuestion-api';
import { useCallback, useEffect } from 'react';

interface IHeader {
  sendEmailToUser: () => void;
  showMenu: boolean;
  setShowMenu: (showMenu: boolean) => void;
}

const Header = ({sendEmailToUser, showMenu, setShowMenu}: IHeader) => {
  const history = useHistory();

  const logoutAction = () => {
    localStorage.clear();
    history.push('/survey-login')
  }

  const getUserInfo = useCallback(async () => {
    try {
      const res = await getProfile()
      if (res?.data?.id) {
        localStorage.setItem('isAnswer', res?.data?.is_answer.toString())
      }
    } catch (error) {
      console.log('error getUserInfo: ', error)
    }
  }, [])

  useEffect(() => {
    const userId = localStorage.getItem('userId')
    if (userId) {
      getUserInfo();
    } else {
      history.push('/survey-login');
    }
  }, []);

  const openNewTab = (link: string) => {
    window.open(link, '_blank');
  }

  return (
    <Menu pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" } isOpen={showMenu} onStateChange={({ isOpen }: any) => setShowMenu(isOpen)}>
      <div className="bm-menu">
        <nav className="bm-item-list">
          <div className="bm-menu-info">
            <p className="m-0 pb-3"><i className="fas fa-user-circle"></i> {localStorage.getItem('nickname') ?? ''}</p>
            <p className="m-0 pb-3">{localStorage.getItem('email') ?? ''}</p>
          </div>
          <div className="bm-menu-content">
            <div className="pb-3"><span aria-hidden="true" onClick={sendEmailToUser} role="button" className="bm-item text-dark"><i className="fas fa-file-medical-alt"></i> 分析シートをメールで送付</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={() => history.push('/faq')} role="button" className="bm-item text-dark"><i className="far fa-question-circle"></i> 用語説明</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={() => openNewTab('https://s-h-d.co.jp/privacy-policy/')} role="button" className="bm-item text-dark"><i className="fas fa-eye-slash"></i> プライバシーポリシー</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={() => history.push('/term-and-service')} role="button" className="bm-item text-dark"><i className="fas fa-file"></i> 利用規約</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={() => openNewTab('https://s-h-d.co.jp/lab/')} role="button" className="bm-item text-dark"><i className="fas fa-book-open"></i> 監修SHD研究所</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={() => openNewTab('https://s-h-d.co.jp/')} role="button" className="bm-item text-dark"><i className="far fa-building"></i> 運営会社</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={() => history.push('/account')} role="button" className="bm-item text-dark"><i className="fas fa-user"></i> アカウント情報</span></div>
            <div className="pb-3"><span aria-hidden="true" onClick={logoutAction} role="button" className="bm-item text-dark"><i className="fas fa-sign-out-alt"></i> ログアウト</span></div>
          </div>
        </nav>
      </div>
    </Menu>
  )
}
export default Header;
